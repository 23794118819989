import React from 'react';
import Modal from '../../commonComponent/Modal/Modal';

export const NetworkConnectionLost = ({ inInterviewRoom }) => {
    const headerText = 'Network Connection Lost!';
    const handleClick = () => {
        window.location.reload(false);
    };
    const Content = () => (
        <div className="popper_message_div d-center" style={{ letterSpacing: '0.15px' }}>
            <div className="finish-title-m pb-3 pt-2">
                Oops! It seems like there's been a hiccup in your network connection, and unfortunately, the interview
                has been interrupted.
            </div>
            <div className="finish-title-m pb-3 pt-2">
                {!inInterviewRoom
                    ? '📞 Please check your connection and click refresh to restart your interview process.'
                    : '📞 Please contact the HR team to resume the interview process.'}
            </div>
        </div>
    );

    return (
        <Modal
            isOpen
            headerText={headerText}
            buttonText="Refresh"
            handleClick={handleClick}
            Content={Content}
            hideButton={inInterviewRoom}
        />
    );
};
