import React, { Fragment, useEffect, useState } from 'react';
import { selectLocalPeerID, useHMSStore, useVideoList } from '@100mslive/react-sdk';
import { StyledVideoList, useTheme } from '@100mslive/roomkit-react';
import VideoTile from './VideoTile';
import useSortedPeers from '../common/useSortedPeers';
import { useAppConfig } from './AppData/useAppConfig';
import { useIsHeadless, useUISettings } from './AppData/useUISettings';
import { UI_SETTINGS } from '../common/constants';

const List = ({
    maxTileCount,
    peers,
    maxColCount,
    maxRowCount,
    includeScreenShareForPeer,
    isTile = false,
    isMobile,
    form,
    practiceStatus,
}) => {
    const { aspectRatio } = useTheme();
    const tileOffset = useAppConfig('headlessConfig', 'tileOffset');
    const isHeadless = useIsHeadless();
    const hideLocalVideo = useUISettings(UI_SETTINGS.hideLocalVideo);
    const localPeerId = useHMSStore(selectLocalPeerID);
    let sortedPeers = useSortedPeers({ peers, maxTileCount });
    if (hideLocalVideo && sortedPeers.length > 1) {
        sortedPeers = filterPeerId(sortedPeers, localPeerId);
    }
    const { ref, pagesWithTiles } = useVideoList({
        peers: sortedPeers,
        maxTileCount,
        maxColCount,
        maxRowCount,
        includeScreenShareForPeer,
        aspectRatio,
        offsetY: getOffset({ isHeadless, tileOffset }),
    });
    const [page, setPage] = useState(0);
    useEffect(() => {
        // currentPageIndex should not exceed pages length
        if (page >= pagesWithTiles.length) {
            setPage(0);
        }
    }, [pagesWithTiles.length, page]);
    return (
        <StyledVideoList.Root ref={ref}>
            <StyledVideoList.Container
                css={{
                    flexWrap: 'wrap',
                    placeContent: 'start',
                    '@md': { placeContent: 'center' },
                    '@media (min-height: 670px) and (max-height: 839px)': {
                        placeContent: 'start',
                    },
                }}
            >
                {pagesWithTiles && pagesWithTiles.length > 0 ? (
                    <Fragment key={pagesWithTiles[0][0].track?.id || pagesWithTiles[0][0].peer.id}>
                        <VideoTile
                            width="100%"
                            height={pagesWithTiles[0][0].height}
                            peerId={pagesWithTiles[0][0].peer?.id}
                            trackId={pagesWithTiles[0][0].track?.id}
                            isTile={isTile}
                            isMobile={isMobile}
                            form={form}
                            practiceStatus={practiceStatus}
                        />
                    </Fragment>
                ) : null}
            </StyledVideoList.Container>
        </StyledVideoList.Root>
    );
};

const VideoList = React.memo(List);

/**
 * returns a new array of peers with the peer with peerId removed,
 * keeps the reference same if peer is not found
 */
function filterPeerId(peers, peerId) {
    const oldPeers = peers; // to keep the reference same if peer is not found
    let foundPeerToFilterOut = false;
    peers = [];
    for (let i = 0; i < oldPeers.length; i++) {
        if (oldPeers[i].id === peerId) {
            foundPeerToFilterOut = true;
        } else {
            peers.push(oldPeers[i]);
        }
    }
    if (!foundPeerToFilterOut) {
        peers = oldPeers;
    }
    return peers;
}

const getOffset = ({ tileOffset, isHeadless }) => {
    if (!isHeadless || isNaN(Number(tileOffset))) {
        return 32;
    }
    return Number(tileOffset);
};

export default VideoList;
