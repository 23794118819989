import React from 'react';
import { Flex, styled, Text } from '@100mslive/roomkit-react';
import FOFBanner from '../../../assets/images/CompletedInterview.svg';
import logo from '../../../images/incruiter-logo.svg';
import { useSelector } from 'react-redux';
export const Completed = () => {
    const whiteLabelLogos = useSelector((state) => state.Interview.whiteLabelLogos);
    const fetchingWhiteLabelStatus = useSelector((state) => state.Interview.fetchingWhiteLabelStatus);
    return (
        <Flex direction="column" css={{ width: '100vw', height: '100vh' }}>
            <Flex
                justify="center"
                direction="column"
                align="center"
                css={{ bg: '#191924', flex: '1 1 0', position: 'relative' }}
            >
                {!fetchingWhiteLabelStatus && (
                    <LogoImg src={whiteLabelLogos?.whiteLogo || logo} alt="Company Logo" width={265} height={'auto'} />
                )}
                <br />
                <LogoImg
                    src={FOFBanner}
                    alt="InCruiter Logo"
                    width={window.innerWidth > 992 ? 615 : '80%'}
                    height={window.innerWidth > 992 ? 354 : 'auto'}
                />
                <Text
                    variant="body1"
                    css={{
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: `${window.innerWidth > 992 ? '28px' : '20px'}`,
                        lineHeight: '55px',
                        color: '#FFFFFF',
                        textAlign: 'center',
                    }}
                >
                    The interview has been completed!
                </Text>
            </Flex>
        </Flex>
    );
};

const LogoImg = styled('img', {
    p: '$2',
});
