import React, { useEffect, useState } from 'react';
import { Box } from '@100mslive/roomkit-react';
import MQuestion from '../components/Pages/components/mobile/MQuestion';
import QuestionButton from '../components/Pages/components/mobile/QuestionButton';
import MobileHeader from '../components/Pages/components/mobile/MobileHeader';
import { GridCenterView } from '../components/gridView';
import MobileNextPopUp from '../components/Pages/components/mobile/MobileNextPopUp';
import useSound from 'use-sound';
import beep from '../assets/audio/beep.mp3';

const IphoneMainView = ({
    form,
    roomId,
    questionInfo,
    isPlaying,
    prepareTime,
    setPrepareTime,
    answerTime,
    setAnswerTime,
    socket,
    handleEndCall,
    recording,
    interviewStarted,
    onlineStatus,
    setAnswerDuration,
    answerDuration,
    handleSubmit,
    candidatePeer,
    displayInput,
    popupInputs,
    setPopupInputs,
    startRecording,
    playAudio,
    skipTimer,
    waitingPrepTime,
    setDisplayInput,
    updateSocket,
}) => {
    const [playBeep] = useSound(beep);
    const viewportHeight = window.innerHeight;
    const prepareTimeOver = "Your time to prepare is over. It's time to begin the answer.";
    const answerTimeOver = 'Your time to answer is over. We are proceeding to the next question.';
    const [questionEnded, setQuestionEnded] = useState(false);
    const [startPrepTime, setStartPrepTime] = useState(false);
    const [startAnswerTime, setStartAnswerTime] = useState(false);
    const calculateHeight = (value) => {
        const difference = viewportHeight - (viewportHeight * (100 - value)) / 100;
        return `${difference}px`;
    };

    const handleSpeak = async () => {
        const value = {
            ...popupInputs,
            isDisabled: true,
            isNextClick: false,
        };
        setPopupInputs(value);
        updateSocket('popupInputs', value);
        playAudio();
    };

    useEffect(() => {
        if (!isPlaying && questionEnded && answerTime === 0) {
            const timerId = setTimeout(() => {
                handleSubmit('Mobile');
                setQuestionEnded(false);
            }, 200);
            return () => {
                clearTimeout(timerId);
            };
        }
    }, [questionEnded, isPlaying, answerTime]);

    useEffect(() => {
        if (!waitingPrepTime && !isPlaying && interviewStarted && !questionEnded && answerTime > 0)
            setStartPrepTime(true);
    }, [isPlaying, waitingPrepTime]);

    useEffect(() => {
        if (!isPlaying && recording && prepareTime === 0 && interviewStarted) setStartAnswerTime(true);
    }, [recording, prepareTime, isPlaying]);

    useEffect(() => {
        if (displayInput?.playBeepSound) {
            const value = {
                ...displayInput,
                playBeepSound: false,
            };
            setDisplayInput(value);
            updateSocket('displayInput', value);
        }
    }, [displayInput?.playBeepSound]);

    useEffect(() => {
        let prepareTimer;
        const prepTimerFunc = async () => {
            if (prepareTime > 0) {
                setPrepareTime(prepareTime - 1);
                updateSocket('prepareTime', prepareTime - 1);
            } else {
                setStartPrepTime(false);
                if (!recording) await startRecording();
                if (!popupInputs?.isNextClick) {
                    const dValue = {
                        ...displayInput,
                        playBeepSound: true,
                    };
                    setDisplayInput(dValue);
                    updateSocket('displayInput', dValue);
                    playBeep();
                }
                clearInterval(prepareTimer);
            }
        };
        if (startPrepTime) {
            prepareTimer = setInterval(() => {
                !onlineStatus ? clearInterval(prepareTimer) : prepTimerFunc();
            }, 1000);
            return () => {
                clearInterval(prepareTimer);
            };
        }
    }, [startPrepTime, prepareTime, onlineStatus, popupInputs?.isNextClick, recording]);

    useEffect(() => {
        if (startAnswerTime) {
            let answerTimer;
            const ansTimerFunc = () => {
                if (answerTime > 0) {
                    setAnswerTime(answerTime - 1);
                    updateSocket('answerTime', answerTime - 1);
                    setAnswerDuration(answerDuration + 1);
                } else {
                    setStartAnswerTime(false);
                    setQuestionEnded(true);
                    clearInterval(answerTimer);
                }
            };
            answerTimer = setInterval(async () => {
                !onlineStatus ? clearInterval(answerTimer) : ansTimerFunc();
            }, 1000);
            return () => {
                clearInterval(answerTimer);
            };
        }
    }, [startAnswerTime, answerTime, onlineStatus]);

    useEffect(() => {
        if (
            displayInput?.message &&
            displayInput?.message !== prepareTimeOver &&
            displayInput?.message !== answerTimeOver
        ) {
            const mValue = {
                ...popupInputs,
                isDisabled: false,
            };
            setPopupInputs(mValue);
            updateSocket('popupInputs', mValue);
        }
    }, [displayInput?.message]);

    return (
        <>
            <MobileNextPopUp
                onButtonClick={handleSpeak}
                questionNumber={parseInt(popupInputs?.questionNumber)}
                handleEndCall={handleEndCall}
                interviewFinished={parseInt(popupInputs?.questionNumber) > parseInt(form?.totalQuestion) + 1}
                socket={socket}
                popupInputs={popupInputs}
                setPopupInputs={setPopupInputs}
                roomId={roomId}
            />
            <Box
                css={{
                    w: '100%',
                    flex: '1 1 0',
                    minHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                id="conferencing"
                data-testid="conferencing"
            >
                <div
                    style={{
                        height: calculateHeight(20),
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <MobileHeader
                        activeQuestion={parseInt(questionInfo?.questionNumber)}
                        totalQuestion={parseInt(form?.totalQuestion)}
                        prepareTime={prepareTime}
                        answerTime={answerTime}
                        handleEndCall={handleEndCall}
                    />
                </div>
                <div style={{ height: calculateHeight(35) }}>
                    <GridCenterView peers={candidatePeer} maxTileCount={1} isMobile form={form} />
                </div>
                <div style={{ height: calculateHeight(35) }}>
                    <MQuestion
                        question={
                            isPlaying || (!isPlaying && !popupInputs?.isDisabled)
                                ? displayInput?.message
                                : displayInput?.displayText
                        }
                        codeBlock={displayInput?.codeBlock}
                        isDisabled={popupInputs?.isDisabled}
                    />
                </div>
                <div style={{ height: calculateHeight(10) }}>
                    <QuestionButton
                        handleEndCall={handleEndCall}
                        questionType={parseInt(questionInfo?.questionNumber) < parseInt(form?.totalQuestion)}
                        submitAnswerButtonEnabled={startAnswerTime || startPrepTime}
                        skipTimer={skipTimer}
                        skipButtonEnabled={startPrepTime}
                        setPrepareTime={setPrepareTime}
                        setAnswerTime={setAnswerTime}
                        socket={socket}
                        roomId={roomId}
                        popupInputs={popupInputs}
                        setPopupInputs={setPopupInputs}
                    />
                </div>
            </Box>
        </>
    );
};
export default IphoneMainView;
