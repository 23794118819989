import React from 'react';
import { QUESTION_TYPES } from '../../../../common/constants';
import Draggable from 'react-draggable';
import VideoList from '../../../VideoList';

export default function SidebarQuestion({ question, questionType, candidatePeer, position, setPosition, codeBlock }) {
    const handleCopy = (e) => {
        e.preventDefault();
    };
    const handleCut = (e) => {
        e.preventDefault();
    };
    const handleDrag = (e, ui) => {
        const { x, y } = ui;
        setPosition({ x, y });
    };
    return (
        <div className="sidebar-question-container">
            <div className="question-number">IncBot</div>
            <div className="ask-question">
                <pre style={{ whiteSpace: 'pre-wrap', margin: '0px' }} onCopy={handleCopy} onCut={handleCut}>
                    <code
                        dangerouslySetInnerHTML={{
                            __html: `${question}`,
                        }}
                    />
                </pre>
                {codeBlock && question && <div className="code-block mt-2">{codeBlock}</div>}
            </div>
            {questionType === QUESTION_TYPES.CODING && candidatePeer?.length > 0 && (
                <Draggable position={position} onDrag={handleDrag}>
                    <div className="video-tile-container draggable video-mini-tile">
                        <VideoList peers={candidatePeer} maxTileCount={1} isTile />
                    </div>
                </Draggable>
            )}
        </div>
    );
}
