import React from 'react';
import { formatTime } from '../../../Feedback/components/Helpers';
import MobileProgressBar from './MobileProgressBar';
import incruiter_logo from '../../../../assets/images/mobilelogo.svg';
import { useSelector } from 'react-redux';
const MobileHeader = ({ form, activeQuestion, prepareTime, answerTime, totalQuestion, handleEndCall }) => {
    const whiteLabelLogos = useSelector((state) => state.Interview.whiteLabelLogos);
    const fetchingWhiteLabelStatus = useSelector((state) => state.Interview.fetchingWhiteLabelStatus);
    return (
        <div className="question-info-container-m">
            <div className="d-flex flex-lg-row flex-column align-items-center justify-content-between">
                <div className="question-timer-div d-flex gap-10">
                    {!fetchingWhiteLabelStatus && (
                        <img
                            src={whiteLabelLogos?.whiteLogo || incruiter_logo}
                            alt="Brand Logo"
                            width="100px"
                            height="auto"
                        />
                    )}
                    <div className="mb-lg-0 mb-3">
                        <div className="mobile-time-info">Time to Prepare</div>
                        <div className="mobile-time-info-timer white-color">{formatTime(prepareTime) ?? '00:00'}</div>
                    </div>
                    <div className="">
                        <div className="mobile-time-info">Time to Answer</div>
                        <div className="mobile-time-info-timer red-color">{formatTime(answerTime) ?? '00:00'}</div>
                    </div>
                    <div className="question-recording d-flex align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path
                                d="M6.99746 12.25C9.87494 12.25 12.25 9.87379 12.25 7C12.25 4.12621 9.86986 1.75 6.99239 1.75C4.11999 1.75 1.75 4.12621 1.75 7C1.75 9.87379 4.12506 12.25 6.99746 12.25ZM6.99746 11.2041C4.66808 11.2041 2.80558 9.33051 2.80558 7C2.80558 4.66949 4.66808 2.80102 6.99239 2.80102C9.32177 2.80102 11.1944 4.66949 11.1995 7C11.2046 9.33051 9.32685 11.2041 6.99746 11.2041ZM7.00254 8.99033C8.10379 8.99033 8.9919 8.10179 8.9919 6.99492C8.9919 5.89821 8.10379 5.00459 7.00254 5.00459C5.89621 5.00459 5.0081 5.89821 5.0081 6.99492C5.0081 8.10179 5.89621 8.99033 7.00254 8.99033Z"
                                fill="#F2464B"
                            />
                        </svg>
                    </div>
                </div>
                <MobileProgressBar
                    totalQuestions={totalQuestion}
                    activeQuestion={activeQuestion}
                    handleEndCall={handleEndCall}
                />
            </div>
        </div>
    );
};

export default MobileHeader;
