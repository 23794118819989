import axios from 'axios';
import { FULL_FACE_DETECTION } from '../../redux/action/types';
import { generateUrl } from '../helper';
const detectFaces = axios.create({
    baseURL: generateUrl(FULL_FACE_DETECTION),
});

detectFaces.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

detectFaces.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default detectFaces;
