import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { Button } from '@100mslive/roomkit-react';
import FaceNotFoundSvg from '../../../../assets/images/face-not-found.svg';
import logo from '../../../../assets/images/incruiter_logo.svg';
import practiceActiveVector from '../../../../assets/images/practice-active-vector.svg';
import practiceDisabledVector from '../../../../assets/images/practice-disabled-vector.svg';
import { practiceQuestion, practiceQuestionUpdate } from '../../../../redux/action';
import { ToastManager } from '../../../Toast/ToastManager';
import PreviewTileM from './PreviewTileM';
import Modal from '../../../commonComponent/Modal/Modal';
import { generateUrl, isMobileDevice } from '../../../../utils/helper';
import { AUDIO_NOT_DETECTED } from '../../../../common/constants';
import { FACE_DETECTED } from '../../utils/constants';
const FaceNotDetectedContent = () => {
    return (
        <div className="face-detection-modal-content">
            <div className="face-detection-modal-content-text1">
                Uh-oh! It looks like your face is not fully visible or not detected by the camera.
            </div>
            <div className="face-detection-modal-content-text2">
                👀 Please ensure that your face is properly positioned and well-lit within the camera frame for a
                starting an interview
            </div>
        </div>
    );
};

const AcknowledgeModalM = memo(
    ({
        onJoin,
        name,
        form,
        token,
        previewError,
        value,
        videoContainerRef,
        speakingPermission,
        recorder,
        isButtonDisabled,
    }) => {
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const { roomId, role } = useParams();
        const basePath = generateUrl('interview_backend');
        const [isStarting, setIsStarting] = useState(false);
        const [practiceStatus, setPracticeStatus] = useState(false);
        const [hasFullFaceDetected, setHasFullFaceDetected] = useState(FACE_DETECTED.DEFAULT);
        const canvasRef = useRef(null);
        const whiteLabelLogos = useSelector((state) => state.Interview.whiteLabelLogos);
        const fetchingWhiteLabelStatus = useSelector((state) => state.Interview.fetchingWhiteLabelStatus);

        useEffect(() => {
            dispatch(
                practiceQuestion({
                    roomId: roomId,
                    callback: (status) => {
                        if (status) {
                            setPracticeStatus(true);
                        }
                    },
                })
            );
        }, [dispatch]);

        const handleText = () => {
            return new Promise((resolve) => {
                debounce(() => {
                    if (value || !speakingPermission) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }, 300)();
            });
        };

        const handlePracticeTest = () => {
            dispatch(
                practiceQuestionUpdate({
                    roomId: roomId,
                })
            );
            const meetingURL = `/practice/room/${role}/${roomId}`;
            navigate(meetingURL, { state: { status: true, form: form, token: token } });
        };

        const handleSubmit = async () => {
            setIsStarting(true);
            const hasTranscribed = await handleText();
            console.log('hasTranscribed', hasTranscribed);
            if (hasTranscribed) onJoin();
            else if (recorder) {
                recorder?.stopRecording(async () => {
                    try {
                        const blob = recorder?.getBlob();
                        console.log(blob);
                        if (blob?.size > 0) {
                            const formData = new FormData();
                            formData.append(
                                'video',
                                blob,
                                isMobileDevice() ? 'recorded-audio.wav' : 'recorded-video.webm'
                            );
                            const response = await fetch(`${basePath}/fetchVoiceTranscribe`, {
                                method: 'POST',
                                body: formData,
                            });
                            console.log('fetchVoiceTranscribe', response);
                            if (!response.ok) {
                                throw new Error('Fetch failed');
                            }
                            const data = await response.json();
                            if (data?.result?.transcript) {
                                onJoin();
                                return;
                            } else {
                                throw new Error('Transcript not found');
                            }
                        } else {
                            throw new Error('Blob is empty');
                        }
                    } catch (error) {
                        console.error('Error fetching voice:', error);
                    } finally {
                        setIsStarting(false);
                        recorder?.startRecording();
                        ToastManager.addToast({
                            title: AUDIO_NOT_DETECTED,
                        });
                    }
                });
            }
        };

        const onCloseModal = () => setHasFullFaceDetected(FACE_DETECTED.DEFAULT);
        const practiceStatusFinal = !(isStarting || isButtonDisabled) && practiceStatus;

        return (
            <div className="audio-video-check-main-container" ref={videoContainerRef}>
                <canvas ref={canvasRef} className="d-none" />
                <Modal
                    isOpen={hasFullFaceDetected === FACE_DETECTED.NOT_DETECTED}
                    showCloseButton
                    onClose={onCloseModal}
                    headerText="Face Not Detected!"
                    Content={FaceNotDetectedContent}
                    imgSrc={FaceNotFoundSvg}
                    buttonText="OK"
                    handleClick={onCloseModal}
                />
                {!fetchingWhiteLabelStatus && (
                    <img src={whiteLabelLogos?.whiteLogo || logo} alt="logo" className="header-image" />
                )}
                <div className="audio-video-check-sub-container-1">
                    <div className="getting-started pb-2">Get Started</div>
                    <div className="getting-started-sub pb-1">Setup your audio and video before joining</div>
                </div>
                <div className="audio-video-check-sub-container-2">
                    <PreviewTileM name={name} error={previewError} isPreview auto />
                    <div className="sample-read-text">
                        Please read the line below to test your camera and microphone before starting the interview
                    </div>
                </div>
                <div className="audio-video-check-sub-container-3">
                    <div className="acknowledge-text-m">
                        Hi, My name is {name}. I acknowledge my identity verification for user authentication.
                    </div>
                    <div className={`transcript-container ${value ? '' : 'transcript-text-disabled'}} `}>
                        {value ?? 'Transcript'}
                    </div>
                    <div className="acknowledge_title_line line-margin"></div>
                    <Button
                        type="submit"
                        disabled={isStarting || isButtonDisabled}
                        onClick={handleSubmit}
                        className={`mx-auto d-flex ${isStarting || isButtonDisabled ? 'start-transcript-check-btn-disabled' : 'start-transcript-check-btn'}`}
                    >
                        Start Interview
                    </Button>
                    {practiceStatusFinal ? (
                        <div className="practice-test">
                            <span onClick={handlePracticeTest}>
                                Practice Before Start
                                <img src={practiceActiveVector} className="practice-vector" alt="Practice Test" />
                            </span>
                        </div>
                    ) : (
                        <div className="practice-test-disabled">
                            Practice Before Start
                            <img src={practiceDisabledVector} className="practice-vector" alt="Practice Test" />
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

export default AcknowledgeModalM;
