import React from 'react';

const ProgressBar = ({ totalQuestions, activeQuestion }) => {
    const circles = [];
    if (activeQuestion <= 3) {
        circles.push(
            <div
                key={1}
                className={`progress-circle  ${
                    activeQuestion === 1 ? 'active' : activeQuestion > 1 ? 'filled' : 'not-filled'
                }`}
            >
                1
            </div>
        );
        for (let i = 2; i <= 3; i++) {
            circles.push(
                <React.Fragment key={i}>
                    <hr className="progress-line" />
                    <div
                        className={`progress-circle ${
                            i === activeQuestion ? 'active' : i < activeQuestion ? 'filled' : 'not-filled'
                        }`}
                    >
                        {i}
                    </div>
                </React.Fragment>
            );
        }
        circles.push(
            <React.Fragment key={4}>
                <hr className="progress-line" />
                <div className="progress-circle not-filled">
                    <span>...</span>
                </div>
            </React.Fragment>
        );
        circles.push(
            <React.Fragment key={5}>
                <hr className="progress-line" />
                <div className="progress-circle not-filled">{totalQuestions}</div>
            </React.Fragment>
        );
    } else if (activeQuestion >= totalQuestions - 2) {
        circles.push(
            <div key={1} className="progress-circle filled">
                1
            </div>
        );
        circles.push(
            <React.Fragment key={2}>
                <hr className="progress-line" />
                <div className="progress-circle filled">
                    <span>...</span>
                </div>
            </React.Fragment>
        );
        for (let i = totalQuestions - 2; i <= totalQuestions; i++) {
            circles.push(
                <React.Fragment key={i}>
                    <hr className="progress-line" />
                    <div
                        className={`progress-circle ${
                            i === activeQuestion ? 'active' : i < activeQuestion ? 'filled' : 'not-filled'
                        }`}
                    >
                        {i}
                    </div>
                </React.Fragment>
            );
        }
    } else {
        circles.push(
            <div key={1} className="progress-circle filled">
                1
            </div>
        );
        circles.push(
            <React.Fragment key={2}>
                <hr className="progress-line" />
                <div className="progress-circle filled">
                    <span>...</span>
                </div>
            </React.Fragment>
        );
        circles.push(
            <React.Fragment key={3}>
                <hr className="progress-line" />
                <div className="progress-circle active">{activeQuestion}</div>
            </React.Fragment>
        );
        circles.push(
            <React.Fragment key={4}>
                <hr className="progress-line" />
                <div className="progress-circle not-filled">
                    <span>...</span>
                </div>
            </React.Fragment>
        );
        circles.push(
            <React.Fragment key={5}>
                <hr className="progress-line" />
                <div className="progress-circle not-filled">{totalQuestions}</div>
            </React.Fragment>
        );
    }

    return <div className="progress-question-bar">{circles}</div>;
};

export default ProgressBar;
