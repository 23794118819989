import React from 'react';
import { styled, Text, textEllipsis } from '@100mslive/roomkit-react';
import { ConnectionIndicator } from './ConnectionIndicator';

const TileConnection = ({ name, peerId, hideLabel, width, isPreview }) => {
    const Wrapper = styled('div', {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'absolute',
        bottom: '$0',
        left: '$0',
        backgroundColor: `${isPreview ? 'transparent' : '$background_dim'}`,
        borderTopRightRadius: '$1',
        maxWidth: '85%',
        zIndex: 1,
        '& p,span': {
            p: '$2 $3',
        },
    });

    return (
        <Wrapper>
            {!hideLabel ? (
                <Text
                    css={{
                        c: '$on_surface_high',
                        ...textEllipsis(width - 60),
                    }}
                    variant="xs"
                >
                    {name}
                </Text>
            ) : null}
            <ConnectionIndicator isTile peerId={peerId} />
        </Wrapper>
    );
};

export default TileConnection;
