import React, { useEffect, useState } from 'react';
import Button from '../../../commonComponent/Button';
import { debounce } from 'lodash';

const MobileNextPopUp = ({
    onButtonClick,
    questionNumber,
    interviewFinished,
    handleEndCall,
    popupInputs,
    setPopupInputs,
    socket,
    roomId,
}) => {
    const [idealTime, setIdealTime] = useState(60);
    const [endCallTime, setEndCallTime] = useState(10);
    useEffect(() => {
        if (!popupInputs?.isDisabled) {
            const idealTimer = setInterval(() => {
                if (idealTime > 0) setIdealTime(idealTime - 1);
                else {
                    if (interviewFinished) handleEndCall(false, 'Mobile Auto Interview Submitted');
                    setPopupInputs({
                        ...popupInputs,
                        idealPopup: true,
                    });
                    socket?.emit(
                        'codeWritten',
                        {
                            type: 'popupInputs',
                            value: {
                                ...popupInputs,
                                idealPopup: true,
                            },
                        },
                        roomId
                    );
                    clearInterval(idealTimer);
                }
            }, 1000);
            return () => {
                clearInterval(idealTimer);
            };
        }
    }, [popupInputs?.isDisabled, idealTime]);
    useEffect(() => {
        if (popupInputs?.idealPopup) {
            const endTimer = setInterval(() => {
                if (endCallTime) setEndCallTime(endCallTime - 1);
                else {
                    handleEndCall(false, 'Mobile Auto Interview Submitted');
                    clearInterval(endTimer);
                }
            }, 1000);
            return () => {
                clearInterval(endTimer);
            };
        }
    }, [popupInputs?.idealPopup, endCallTime]);
    const buttonContent =
        questionNumber === 2
            ? 'Start'
            : popupInputs?.isNextClick
              ? 'Okay'
              : interviewFinished
                ? 'Okay'
                : 'Next Question';
    const mainContent =
        questionNumber === 2
            ? "Hurrah! We are all set to begin your interview. Please click on 'Start' button."
            : popupInputs?.isNextClick
              ? "Let's proceed to the next question"
              : interviewFinished
                ? 'Your time to answer is over. The interview has been ended.'
                : "Your time to answer is over. Click on 'Next Question' to move ahead.";
    return (
        <div className={`mobile-next-popup ${popupInputs?.isDisabled ? 'd-none' : 'd-flex'}`}>
            <div className="mobile-next-popup-inner">
                <div className="mobile-next-popup-content">
                    {!popupInputs?.idealPopup
                        ? mainContent
                        : "We didn't find any action to move ahead with the interview process."}
                    <br />
                    {popupInputs?.idealPopup ? 'Do you wish to end the interview?' : ''}
                </div>
                {!popupInputs?.idealPopup && (
                    <Button
                        text={buttonContent}
                        className={`mobile-common-btn mobile-popup-btn mt-3`}
                        onClick={
                            interviewFinished
                                ? debounce(() => handleEndCall(false, 'Mobile Interview Submitted'), 300)
                                : debounce(() => {
                                      onButtonClick();
                                      setIdealTime(60);
                                      setEndCallTime(10);
                                  }, 300)
                        }
                    />
                )}
                {popupInputs?.idealPopup && (
                    <div className="mobile-next-popup-buttons mt-3">
                        <Button
                            text="Cancel"
                            className={`mobile-common-btn mobile-popup-btn cancel`}
                            onClick={debounce(() => {
                                onButtonClick();
                                setIdealTime(60);
                                setEndCallTime(10);
                                const value = {
                                    ...popupInputs,
                                    idealPopup: false,
                                    isDisabled: true,
                                };
                                setPopupInputs(value);
                                socket?.emit(
                                    'codeWritten',
                                    {
                                        type: 'popupInputs',
                                        value: value,
                                    },
                                    roomId
                                );
                            }, 300)}
                        />
                        <Button
                            text="Finish"
                            className={`mobile-common-btn mobile-popup-btn ml-2`}
                            onClick={debounce(() => handleEndCall(false, 'Mobile Interview Submitted'), 300)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default MobileNextPopUp;
