import { useMedia } from 'react-use';
import { selectDominantSpeaker, selectIsConnectedToRoom, useHMSStore } from '@100mslive/react-sdk';
import { VolumeOneIcon } from '@100mslive/react-icons';
import { config as cssConfig, Flex, styled, Text, textEllipsis, useTheme } from '@100mslive/roomkit-react';
import { useLogo } from '../AppData/useUISettings';
import { isStreamingKit } from '../../common/utils';
import { useSelector } from 'react-redux';

export const SpeakerTag = () => {
    const dominantSpeaker = useHMSStore(selectDominantSpeaker);
    return dominantSpeaker && dominantSpeaker.name ? (
        <Flex
            align="center"
            justify="center"
            css={{
                flex: '1 1 0',
                color: '$on_surface_high',
                '@md': { display: 'none' },
            }}
        >
            <VolumeOneIcon />
            <Text variant="md" css={{ ...textEllipsis(200), ml: '$2' }} title={dominantSpeaker.name}>
                {dominantSpeaker.name}
            </Text>
        </Flex>
    ) : (
        <></>
    );
};

const LogoImg = styled('img', {
    maxHeight: '$14',
    p: '$2',
    w: 'auto',
    '@md': {
        maxHeight: '$12',
    },
});

export const Logo = () => {
    const logo = useLogo();
    const isMobile = useMedia(cssConfig.media.md);
    const isConnected = useHMSStore(selectIsConnectedToRoom);
    const whiteLabelLogos = useSelector((state) => state.Interview.whiteLabelLogos);
    const fetchingWhiteLabelStatus = useSelector((state) => state.Interview.fetchingWhiteLabelStatus);
    if (isConnected && isMobile && isStreamingKit()) {
        return null;
    }
    return (
        <>
            {!fetchingWhiteLabelStatus && (
                <LogoImg
                    src={whiteLabelLogos?.whiteLogo || logo || require('../../assets/images/incruiter_logo.svg')}
                    className="header-components-logo"
                    alt="Brand Logo"
                />
            )}
        </>
    );
};
