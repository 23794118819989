import React from 'react';
import permissionLoader from '../../../../assets/images/permissionLoader.gif';
import tick from '../../../../assets/images/tick.svg';
import { ConnectionIndicator } from '../../../Connection/ConnectionIndicator';
import Modal from '../../../commonComponent/Modal/Modal';

const AudioVideoPrechecks = ({
    peerId,
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    downlinkQuality,
    startRecording,
    startListening,
    setTestValidation,
    setCheckNetworkError,
}) => {
    const headerText = 'Please allow your camera and microphone permissions before starting the interview';
    const isButtonDisabled = !(isLocalAudioEnabled && isLocalVideoEnabled && downlinkQuality >= 4);
    const getNetworkStatus = (strength) => {
        if (strength >= 4) return 'Strong';
        else if (strength < 4 && strength > 3) return 'Medium';
        else return 'Weak';
    };

    const getNetworkClass = (strength) => {
        if (strength >= 4) return 'strong-network';
        else if (strength < 4 && strength > 3) return 'medium-network';
        else return 'poor-network';
    };

    const Content = () => (
        <div className="acknowledge_validation_div">
            <div className="d-flex align-items-center mb-4">
                <div className="acknowledge_validation_text my-auto w-50 text-start">Audio</div>
                <div className="acknowledge_validation_text my-auto w-50">
                    {isLocalAudioEnabled ? (
                        <img src={tick} alt="" />
                    ) : (
                        <img src={permissionLoader} className="gif" alt="X" />
                    )}
                </div>
            </div>
            <div className="d-flex align-items-center mb-4">
                <div className="acknowledge_validation_text my-auto w-50 text-start">Video</div>
                <div className="acknowledge_validation_text my-auto w-50">
                    {isLocalVideoEnabled ? (
                        <img src={tick} alt="" />
                    ) : (
                        <img src={permissionLoader} className="gif" alt="X" />
                    )}
                </div>
            </div>
            <div className="d-flex align-items-center mb-4">
                <div className="acknowledge_validation_text my-auto w-50 text-start">Network</div>
                <div className="d-flex align-items-center w-50 justify-content-center">
                    <ConnectionIndicator isTile peerId={peerId} isPreview />
                    <div className={`ms-2 ${getNetworkClass(downlinkQuality)}`}>
                        {getNetworkStatus(downlinkQuality)}
                    </div>
                </div>
            </div>
        </div>
    );

    const handleNextClick = () => {
        startRecording();
        setTestValidation(true);
        startListening();
        setCheckNetworkError(false);
    };

    return (
        <>
            <Modal
                isOpen
                hideButton={false}
                handleClick={handleNextClick}
                headerText={headerText}
                buttonText="Next"
                Content={Content}
                className="audio-video-prechecks-modal"
                isBtnDisabled={isButtonDisabled}
            />
        </>
    );
};

export default AudioVideoPrechecks;
