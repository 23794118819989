import { Fragment, useState } from 'react';
import { Box, IconButton, styled, Tooltip } from '@100mslive/roomkit-react';
import FinishInterviewPopUp from '../../../Pages/components/portal/FinishInterviewPopUp';
import phone from '../../../../assets/images/endCall.svg';
export const MobileLeaveRoom = ({ handleEndCall }) => {
    const [isFinish, setIsFinish] = useState(false);
    return (
        <Fragment>
            <LeaveIconButton
                variant="danger"
                key="LeaveRoom"
                data-testid="leave_room_btn"
                onClick={() => setIsFinish(true)}
                className="end-call-btn"
            >
                <Tooltip title="Leave Room">
                    <Box>
                        <img src={phone} alt="End Call" className="end-call"></img>
                    </Box>
                </Tooltip>
            </LeaveIconButton>
            {isFinish && <FinishInterviewPopUp handleLeave={handleEndCall} setIsFinish={setIsFinish} />}
        </Fragment>
    );
};

const LeaveIconButton = styled(IconButton, {
    color: '$on_primary_high',
    h: '$14',
    px: '$8',
    r: '$1',
    bg: '$alert_error_default',
    '&:not([disabled]):hover': {
        bg: '$alert_error_bright',
    },
    '&:not([disabled]):active': {
        bg: '$alert_error_bright',
    },
    '@md': {
        px: '$4',
        mx: 0,
    },
});
