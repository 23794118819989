import React, { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { ToastManager } from '../../../Toast/ToastManager';
import MainPage from './MainPage';
import ResumeInput from './ResumeInput';
import Button from '../../../commonComponent/Button';
import ContactInput from '../../../commonComponent/ContactInput';
import Input from '../../../commonComponent/Input';

const SignUpForm = ({ setForm, form, handleSubmit }) => {
    // for disabling the next btn when the form is not filled
    const [isDisabled, setIsDisabled] = useState(true);
    useEffect(() => {
        const { name, email, phone } = form.candidate;
        if (!name || !email || !phone || !form?.resume) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [form]);
    const mailChecker = (value) => {
        return value.replace(/[^a-zA-Z0-9\_\-\@\.\+]/g, '');
    };
    const nameChecker = (value) => {
        return value.replace(/[^A-Za-z\s\.]/g, '');
    };
    const checkFileValidation = (files) => {
        let isValid = false;
        if (
            files.type === 'application/pdf' ||
            files.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            files.type === 'application/msword'
        ) {
            isValid = true;
        }
        return isValid;
    };
    const handleFileChange = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        const { name, files } = e.target;
        if (checkFileValidation(files[0])) {
            setForm({
                ...form,
                [name]: files[0],
            });
        } else {
            e.target.value = null;
            ToastManager.addToast({
                title: 'Uploaded file is not valid. Only DOC, DOCX, PDF files are allowed.',
            });
        }
    };
    return (
        <MainPage>
            <>
                <h4 className="title">Welcome</h4>
                <div className="title-line d-lg-none"></div>
                <p className="sub-title mt-lg-4 mt-3 text-center d-lg-none">Please verify before starting</p>
                <div className="form-container content-container">
                    <div className="mb-2 w-100">
                        <Input
                            label="Your Name"
                            isRequired={true}
                            labelClass="my-1 f-l4 f-sem-bol"
                            inputClass="f-l f-reg rounded input-shadow"
                            className="common-input"
                            name="name"
                            type="text"
                            value={form?.candidate?.name}
                            onChange={(e) => {
                                setForm((prev) => ({
                                    ...prev,
                                    candidate: {
                                        ...prev?.candidate,
                                        name: nameChecker(e.target.value),
                                    },
                                }));
                            }}
                            placeholder="Enter your name"
                            borderNone
                        />
                    </div>
                    <div className="w-100">
                        <ResumeInput form={form} handleFileChange={handleFileChange} />
                    </div>
                    <div className="mb-2 w-100">
                        <Input
                            label="Email Id"
                            isRequired={true}
                            labelClass="my-1 f-l4 f-sem-bol"
                            inputClass="f-l f-reg rounded input-shadow"
                            className="common-input"
                            name="email"
                            type="text"
                            placeholder="Enter your email"
                            borderNone
                            value={form?.candidate?.email}
                            onChange={(e) => {
                                setForm((prev) => ({
                                    ...prev,
                                    candidate: {
                                        ...prev?.candidate,
                                        email: mailChecker(e.target.value),
                                    },
                                }));
                            }}
                        />
                    </div>
                    <div className="mb-2 w-100">
                        <ContactInput
                            labelClass="c-label my-1 f-l4 f-sem-bol"
                            inputClass="common-input"
                            formData={`${form?.candidate?.countryCode ? form?.candidate?.countryCode : ''}${
                                form?.candidate?.phone ? form?.candidate?.phone : ''
                            }`}
                            setPhoneNumber={(newNumber, code) => {
                                setForm((prev) => ({
                                    ...prev,
                                    candidate: {
                                        ...prev?.candidate,
                                        phone: `+${code}${newNumber}`,
                                    },
                                }));
                            }}
                            label="Whatsapp Number"
                            placeholder="Enter whatsapp number"
                            isRequired={true}
                            borderNone
                            propertyName="phone"
                        />
                    </div>
                    <Button
                        text="Next"
                        disabled={isDisabled}
                        className="common-btn mt-lg-4 mt-2 mx-auto"
                        onClick={debounce(() => handleSubmit(), 300)}
                    />
                </div>
            </>
        </MainPage>
    );
};

export default SignUpForm;
