import React from 'react';
export const Screenshots = ({ data }) => {
    return (
        <div className="screenshots-wrapper break-stop">
            <div className="skills-div">
                <span className="soft-skills-span">Screenshots</span>
            </div>
            <div className="screenshots-container">
                {data &&
                    data.length > 0 &&
                    data.map((itm, key) => (
                        <div className="screenshot-wrapper break-stop" key={key}>
                            <img src={itm} alt="Images"></img>
                        </div>
                    ))}
            </div>
        </div>
    );
};
