import React, { Fragment } from 'react';
import { useMedia } from 'react-use';
import { Box, config as cssConfig } from '@100mslive/roomkit-react';
import VideoList from './VideoList';
const MAX_TILES_FOR_MOBILE = 4;
export const GridCenterView = ({ peers, maxTileCount, isMobile, form, practiceStatus }) => {
    const mediaQueryLg = cssConfig.media.md;
    const limitMaxTiles = useMedia(mediaQueryLg);
    return (
        <Fragment>
            <Box
                css={{
                    flex: '1 1 0',
                    height: '100%',
                    '@media (min-width: 1025px)': {
                        mx: '$8',
                    },
                }}
            >
                {peers && peers.length > 0 && (
                    <VideoList
                        peers={peers}
                        maxTileCount={limitMaxTiles ? MAX_TILES_FOR_MOBILE : maxTileCount}
                        isMobile={isMobile}
                        form={form}
                        practiceStatus={practiceStatus}
                    />
                )}
            </Box>
        </Fragment>
    );
};
