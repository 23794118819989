import React from 'react';
import PreviewJoin from './PreviewJoin/PreviewJoin';
import PreviewJoinM from './PreviewJoinM/PreviewJoinM';
import { isMobileDevice } from '../../utils/helper';

const PreviewContainer = ({
    token,
    onJoin,
    env,
    form,
    skipPreview,
    initialName,
    asRole,
    setIsMount,
    positionTitle,
    companyName,
    isNetworkError,
    permissionsChecks,
    downlinkQuality,
    mediaRecorder,
    startRecording,
    setCheckNetworkError,
    isButtonDisabled,
}) => {
    return isMobileDevice() ? (
        <PreviewJoinM
            initialName={initialName}
            skipPreview={skipPreview}
            env={env}
            onJoin={onJoin}
            token={token}
            asRole={asRole}
            form={form}
            setIsMount={setIsMount}
            positionTitle={positionTitle}
            companyName={companyName}
            isNetworkError={isNetworkError}
            permissionsChecks={permissionsChecks}
            downlinkQuality={downlinkQuality}
            mediaRecorder={mediaRecorder}
            startRecording={startRecording}
            setCheckNetworkError={setCheckNetworkError}
            isButtonDisabled={isButtonDisabled}
        />
    ) : (
        <PreviewJoin
            initialName={initialName}
            skipPreview={skipPreview}
            env={env}
            onJoin={onJoin}
            token={token}
            asRole={asRole}
            form={form}
            setIsMount={setIsMount}
            positionTitle={positionTitle}
            companyName={companyName}
            isNetworkError={isNetworkError}
            permissionsChecks={permissionsChecks}
            downlinkQuality={downlinkQuality}
            mediaRecorder={mediaRecorder}
            startRecording={startRecording}
            setCheckNetworkError={setCheckNetworkError}
            isButtonDisabled={isButtonDisabled}
        />
    );
};

export default PreviewContainer;
