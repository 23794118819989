import React from 'react';
import { Flex, Text } from '@100mslive/roomkit-react';
import CanceledImg from '../../assets/images/cancelled_icon.svg';

const CanceledInterview = () => {
    return (
        <Flex css={{ height: '100vh', width: '100vw' }} direction="column">
            <Flex direction="column" css={{ size: '100%' }}>
                <Flex
                    justify="center"
                    direction="column"
                    align="center"
                    css={{
                        bg: '#191924',
                        flex: '1 1 0',
                        position: 'relative',
                        padding: '0 1.5rem',
                    }}
                >
                    <img src={CanceledImg} alt="" className="canceled_icon" />
                    <Text
                        variant="body1"
                        css={{
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '30px',
                            lineHeight: '40px',
                            color: '#fff',
                            textAlign: 'center',
                        }}
                    >
                        This interview is cancelled. Please contact employer
                        <br /> for further details.
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default CanceledInterview;
