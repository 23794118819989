import React from 'react';
const MQuestion = ({ question, isDisabled, codeBlock }) => {
    return (
        <div className="ask-question-div-mobile">
            <div className="question-number">IncBot</div>
            {isDisabled && (
                <div className="question-answer-text-mobile">
                    <pre style={{ whiteSpace: 'pre-wrap', margin: '0px' }}>
                        <code
                            dangerouslySetInnerHTML={{
                                __html: `${question}`,
                            }}
                        />
                    </pre>
                    {codeBlock && <div className="code-block mobile mt-2">{codeBlock}</div>}
                </div>
            )}
        </div>
    );
};

export default MQuestion;
