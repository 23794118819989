import React, { useRef } from 'react';
import file from '../../../../assets/images/file.svg';

const ResumeInput = ({ form, handleFileChange }) => {
    const hiddenFileInput = useRef(null);
    const hasResume = form?.resume;
    const resumeName = form?.resume?.name;
    const handleInputClick = () => {
        hiddenFileInput.current.click();
    };
    return (
        <div className="cpp-input-container mb-2">
            <label htmlFor="resume" className="c-label my-1 f-l f-sem-bol">
                Resume
                <span className="asterisk f-12">&#42;</span>
            </label>
            <div className="position-relative file-input d-flex align-items-center justify-content-end rounded">
                <input
                    id="fileInput"
                    type="file"
                    className={`f-l f-reg rounded input-shadow position-relative real-file-input cursor-pointer hidden ${
                        !hasResume && !resumeName && 'hidden'
                    }`}
                    name="resume"
                    placeholder=""
                    onChange={handleFileChange}
                    ref={hiddenFileInput}
                />
                <div
                    className={`cursor-pointer f-l f-reg rounded input-shadow real-file-input position-relative ${
                        (hasResume || resumeName) && 'hidde'
                    }`}
                    onClick={handleInputClick}
                >
                    {resumeName ? resumeName : 'Choose File'}
                </div>
                <img src={file} alt="file" className="file h-75 position-absolute img-fluid" />
            </div>
            <span className="upload-type">*supported file types are .pdf, .docx, .doc</span>
        </div>
    );
};

export default ResumeInput;
