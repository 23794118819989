import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import _debounce from 'lodash/debounce';
import cross from '../../../assets/images/cross.svg';
import emptyStar from '../../../assets/images/emptyStar.svg';
import filledStar from '../../../assets/images/filledStar.svg';
import { postInterviewFeedback } from '../../../redux/action';
import { ToastManager } from '../../Toast/ToastManager';
const InterviewFeedback = ({ setIsInterviewFeedbackVisible, roomId }) => {
    const dispatch = useDispatch();
    const [interviewFeedback, setInterviewFeedback] = useState({
        roomId: roomId,
        platform: '',
        suggestions: '',
    });

    const handleClose = () => {
        setIsInterviewFeedbackVisible(false);
    };

    const handleStarClick = (ele) => {
        setInterviewFeedback((prevState) => ({
            ...prevState,
            platform: ele,
        }));
    };

    const handleSubmit = _debounce(() => {
        if (interviewFeedback?.platform) {
            dispatch(
                postInterviewFeedback({
                    data: interviewFeedback,
                    callback: () => {
                        setIsInterviewFeedbackVisible(false);
                    },
                })
            );
        } else {
            ToastManager.addToast({
                title: "Interview Platform can't be empty.",
            });
        }
    }, 300);

    const arr = ['1', '2', '3', '4', '5'];

    return (
        <div className="feedback-wrapper-new">
            <div className="feeback-collection">
                <div className="feddback-header-wrapper">
                    <p className="feeback-collection-header f-bol f-18">Interview Feedback</p>
                    <img src={cross} alt="cut" className="feedback-close-btn" onClick={handleClose}></img>
                </div>
                <div className="feedback-inner-div">
                    <p className="feedback-inner-head f-bol f-18">
                        Please Share your valuable feedback to help us improve!
                    </p>
                    <p className="feedback-inner-div-p f-reg f-14">Interview Platform</p>
                    <div className="star-container">
                        {arr.map((ele, i) => (
                            <button key={i} className="star-btn" onClick={() => handleStarClick(ele)}>
                                <img alt="star" src={ele <= interviewFeedback?.platform ? filledStar : emptyStar}></img>
                            </button>
                        ))}
                    </div>
                    <p className="feedback-inner-div-p f-reg f-14">Suggestions:</p>
                    <textarea
                        className="feedback-input"
                        value={interviewFeedback?.suggestions ?? ''}
                        onChange={(e) =>
                            setInterviewFeedback((prevState) => ({
                                ...prevState,
                                suggestions: e.target.value,
                            }))
                        }
                    ></textarea>
                    <center>
                        <button
                            className={`feedback-submit${interviewFeedback.platform ? '' : ' disabled'}`}
                            onClick={handleSubmit}
                            disabled={!interviewFeedback.platform}
                        >
                            Submit
                        </button>
                    </center>
                </div>
            </div>
        </div>
    );
};

export default InterviewFeedback;
