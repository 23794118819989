import { isValidPhoneNumber } from 'react-phone-number-input';
import validator from 'validator';
import { ToastManager } from '../../Toast/ToastManager';
import { validateEmail } from '../../../utils/helper';
function validatePostSingleCandidate(data) {
    const errors = {};
    let message = '';
    if (data.resume === '') {
        errors.resume = message = 'Please select a Resume file';
    }
    if (validator.isEmpty(data?.candidate?.name)) {
        errors.candidateName = message = 'Please Enter the Candidate Name';
    }
    if (validator.isEmpty(data?.candidate?.email)) {
        errors.email = message = 'Please Enter the Candidate Email';
    } else if (!validateEmail(data?.candidate?.email)) {
        errors.email = message = 'Please Enter the Valid Email';
    }
    if (validator.isEmpty(data?.candidate?.phone)) {
        errors.phone = message = 'Please Enter the Whatsapp Number';
    } else if (!isValidPhoneNumber(data?.candidate?.phone)) {
        errors.phone = message = 'Please Enter the Valid Whatsapp Number';
    }
    if (message) {
        ToastManager.addToast({
            title: message,
        });
    }
    return { errors, isValid: Object.keys(errors).length <= 0 };
}
export default validatePostSingleCandidate;
