import { useSelector } from 'react-redux';
import IncBot from '../../../../assets/images/portal/incBot.svg';
import Logo from '../../../../assets/images/portal/white-logo.svg';

const MainPage = ({ children, title, className }) => {
    const whiteLabelLogos = useSelector((state) => state.Interview.whiteLabelLogos);
    const fetchingWhiteLabelStatus = useSelector((state) => state.Interview.fetchingWhiteLabelStatus);
    return (
        <div className={`main-page-container ${className}`}>
            <div className="header-main-logo-container">
                {!fetchingWhiteLabelStatus && (
                    <img
                        src={whiteLabelLogos?.whiteLogo || Logo}
                        className="d-lg-none d-inline-block header-main-logo"
                        alt="log"
                    />
                )}
            </div>
            <div className="row portal_container">
                <div className="col-lg-5 portal_left_section position-relative d-lg-inline d-none">
                    {!fetchingWhiteLabelStatus && (
                        <img src={whiteLabelLogos?.whiteLogo || Logo} alt="incbot" className="form-logo" />
                    )}
                    <br />
                    <br />
                    {title ? (
                        <h4 className="title">
                            I am your <br /> Interview Partner <br /> for today
                        </h4>
                    ) : (
                        <h4 className="title">
                            Hello!
                            <br /> I am IncBot{' '}
                        </h4>
                    )}
                    <img src={IncBot} alt="incbot" className="incbot img-fluid" />
                </div>
                <div className="col-lg-7 col-12 portal_right_section h-100vh">{children}</div>
            </div>
        </div>
    );
};

export default MainPage;
