import React from 'react';
import ProgressBar from './ProgressBar';
import { getAssetSrc } from '../../../../utils/helper';
import { LeaveRoom } from '../../../LeaveRoom';
import { ASSET_NAMES } from '../../../../common/constants';
import { useSelector } from 'react-redux';

const QuestionInfo = ({ form, questionInfo, handleEndCall }) => {
    const totalQuestion = form?.totalQuestion ? parseInt(form?.totalQuestion) : 0;
    const orgName = form?.companyName ?? 'InCruiter';
    const posName = form?.positionTitle ?? 'Position Name';
    const whiteLabelLogos = useSelector((state) => state.Interview.whiteLabelLogos);
    const fetchingWhiteLabelStatus = useSelector((state) => state.Interview.fetchingWhiteLabelStatus);
    return (
        <div className="question-info-container">
            <div className="question-info-div d-flex flex-column flex-sm-row gap-10 gap-sm-50 align-items-center justify-content-between">
                {!fetchingWhiteLabelStatus && (
                    <img
                        src={whiteLabelLogos?.whiteLogo || getAssetSrc(ASSET_NAMES.INCRUITER_LOGO)}
                        alt="logo"
                        className="main-view-logo"
                    />
                )}
                <div className="end-btn-container">
                    <LeaveRoom handleEndCall={handleEndCall} />
                </div>
                <div className="header-sidebar-container">
                    <div className="question-timer-div-main d-flex gap-10 org-position-container">
                        <div className="question-titlev2 org-position" title={orgName}>
                            {orgName}
                        </div>
                        <div className="question-title org-position" title={posName}>
                            {posName}
                        </div>
                    </div>
                    <ProgressBar totalQuestions={totalQuestion} activeQuestion={questionInfo?.questionNumber} />
                </div>
            </div>
        </div>
    );
};

export default QuestionInfo;
