import { styled } from '@100mslive/roomkit-react';
import { getAssetSrc } from '../utils/helper';
import { ASSET_NAMES } from '../common/constants';

const Wrapper = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    top: '$2',
    left: '$2',
    maxWidth: '85%',
    zIndex: 1,
    '& p,span': {
        p: '$2 $3',
    },
});

const VideoRecordingTile = () => {
    return (
        <Wrapper>
            <img src={getAssetSrc(ASSET_NAMES.RECORDING_ICON)} className="d-none d-lg-block" />
            <div className="recording-view d-none d-lg-block">Recording</div>
        </Wrapper>
    );
};

export default VideoRecordingTile;
