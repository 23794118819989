import { memo } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
const ContactInput = memo(({ label, labelClass, isRequired, formData, error, setPhoneNumber, placeholder }) => {
    const handleCountryCode = (value, country, e, formattedValue) => {
        let number = value;
        let code = country.dialCode;
        let newNumber = number.slice(code.length, number.length);
        setPhoneNumber(newNumber, code);
    };
    return (
        <>
            <label htmlFor="phone" className={labelClass}>
                {label} {isRequired && <span className="asterisk f-12 text-theme-red">*</span>}
            </label>
            <PhoneInput
                name="phone"
                country="in"
                value={formData}
                inputProps={{
                    name: 'phone',
                    autoFocus: true,
                }}
                onChange={(value, country, e, formattedValue) => handleCountryCode(value, country, e, formattedValue)}
                containerClass={`d-flex w-100 flagDropdown f-l f-reg rounded input-shadow ${error ? 'error' : ''}`}
                placeholder={placeholder}
                inputClass="w-100 f-12"
                dropdownClass="rounded"
            />
        </>
    );
});
export default ContactInput;
