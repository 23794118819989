import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { switchTab } from '../../../../redux/action';
const TabSwitch = ({ interviewId }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                dispatch(
                    switchTab({
                        interviewId: interviewId,
                        message: 'Candidate switched to another tab',
                    })
                );
            } else if (document.visibilityState === 'visible') {
                dispatch(
                    switchTab({
                        interviewId: interviewId,
                        message: 'Candidate came back to the tab',
                    })
                );
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    return <></>;
};

export default TabSwitch;
