import React from 'react';
import Button from '../../../commonComponent/Button';
import { debounce } from 'lodash';
const QuestionButton = ({
    handleEndCall,
    questionType,
    submitAnswerButtonEnabled,
    skipTimer,
    skipButtonEnabled,
    setAnswerTime,
    setPrepareTime,
    socket,
    roomId,
    popupInputs,
    setPopupInputs,
}) => {
    return (
        <div className="question-info-container-m">
            <div className="d-flex flex-lg-row flex-column align-items-center justify-content-between">
                <div className="question-timer-div d-flex gap-10">
                    <>
                        <Button
                            text="Skip Prep Time"
                            className={`mobile-common-btn mt-lg-3 new-mt${skipButtonEnabled ? '' : ' disabled'}`}
                            onClick={() => skipTimer()}
                            disabled={!skipButtonEnabled}
                        />
                        <Button
                            text={questionType ? 'Next Question' : 'Submit'}
                            className={`mobile-common-btn mt-lg-3 new-mt${
                                !submitAnswerButtonEnabled ? ' disabled' : ''
                            }`}
                            onClick={
                                questionType
                                    ? debounce(() => {
                                          setPopupInputs({
                                              ...popupInputs,
                                              isNextClick: true,
                                          });
                                          socket?.emit(
                                              'codeWritten',
                                              {
                                                  type: 'popupInputs',
                                                  value: {
                                                      ...popupInputs,
                                                      isNextClick: false,
                                                  },
                                              },
                                              roomId
                                          );
                                          setPrepareTime(0);
                                          socket?.emit('codeWritten', { type: 'prepareTime', value: 0 }, roomId);
                                          setAnswerTime(0);
                                          socket?.emit('codeWritten', { type: 'answerTime', value: 0 }, roomId);
                                      }, 300)
                                    : debounce(() => handleEndCall(false, 'Mobile Interview Submitted'), 300)
                            }
                            disabled={!submitAnswerButtonEnabled}
                        />
                    </>
                </div>
            </div>
        </div>
    );
};

export default QuestionButton;
