import { combineReducers } from 'redux';
import Interview from './interviewReducer';

const appReducer = combineReducers({ Interview });

const reducers = (state, action) => {
    return appReducer(state, action);
};

export default reducers;
