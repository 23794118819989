import React from 'react';
import { parseMarkdown } from '../../../utils/helper';

export const ResumeSummary = ({ data = {} }) => {
    return (
        <div className="candidate-skills-wrapper">
            <span className="candidate-skills-heading">Resume Summary</span>
            <div className="font-style-soft-skill resume-summary text-start">
                <div dangerouslySetInnerHTML={{ __html: parseMarkdown(data?.resumeSummary || "No valid candidate information provided in the resume.") }} />
            </div>
        </div>
    );
};
