import React, { useEffect, useState } from 'react';
import { useSearchParam } from 'react-use';
import { selectConnectionQualityByPeerID, selectLocalPeer, useHMSStore, usePreviewJoin } from '@100mslive/react-sdk';
import { StyledVideoTile, useBorderAudioLevel, Video } from '@100mslive/roomkit-react';
import { NetworkConnectionLost } from '../components/NetworkConnectionLost';
import ideaLogo from '../../../assets/images/fi_427735.svg';
import { getAssetSrc } from '../../../utils/helper';
import { ASSET_NAMES } from '../../../common/constants';
const PracticeVideoView = ({ form, setVideoEnabled, token }) => {
    const localPeer = useHMSStore(selectLocalPeer);
    const peerId = localPeer?.id;
    const borderAudioRef = useBorderAudioLevel(localPeer?.audioTrack);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [isNetworkError, setIsNetworkError] = useState(false);
    const asRole = useSearchParam('preview_as_role');
    const downlinkQuality = useHMSStore(selectConnectionQualityByPeerID(peerId))?.downlinkQuality;
    useEffect(() => {
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
        };
        window.addEventListener('online', handleStatusChange);
        window.addEventListener('offline', handleStatusChange);
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);

    const { preview } = usePreviewJoin({
        name: form?.candidateName,
        token,
        initEndpoint: 'https://prod-init.100ms.live/init',
        initialSettings: {
            isAudioMuted: false,
            isVideoMuted: false,
            speakerAutoSelectionBlacklist: ['Yeti Stereo Microphone'],
        },
        captureNetworkQualityInPreview: true,
        handleError: (_, method) => {},
        asRole,
    });

    useEffect(() => {
        if (token) {
            preview();
            setVideoEnabled(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useEffect(() => {
        if (downlinkQuality) {
            if (downlinkQuality <= 3) {
                setIsNetworkError(true);
            } else {
                setIsNetworkError(false);
            }
        }
    }, [downlinkQuality]);

    if (isNetworkError) return <NetworkConnectionLost />;

    return (
        <div
            style={{
                margin: '.62rem .0625rem 0 .0625rem',
                position: 'relative',
            }}
        >
            <StyledVideoTile.Container
                css={{
                    bg: '$surface_default',
                    width: 'unset',
                    height: 'auto',
                    '@md': { maxHeight: '300px' },
                    '@sm': {
                        width: '100%',
                        maxWidth: '100%',
                        marginTop: '0',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    },
                }}
                ref={borderAudioRef}
            >
                <Video id="preview_tile" mirror={true} trackId={localPeer?.videoTrack} data-testid="preview_tile" />
            </StyledVideoTile.Container>
            <div className="practice-text-title">
                <img src={ideaLogo} alt="Practice Test" />
                Practice Test
            </div>
            <div
                style={{
                    position: 'absolute',
                    bottom: '0px',
                    left: '0px',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    color: '#fff',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    textAlign: 'center',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                    letterSpacing: '0.25px',
                }}
            >
                {form?.candidateName}
            </div>
            <div
                style={{
                    position: 'absolute',
                    bottom: '0px',
                    right: '0px',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    color: '#fff',
                    borderRadius: '10px',
                }}
            >
                <img src={getAssetSrc(ASSET_NAMES.BOT_GIF)} alt="Animated GIF" className="gifImage d-lg-block d-none" />
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: '5px',
                        position: 'relative',
                        background: '#2E2E41',
                        display: 'inline-flex',
                        padding: '2px 10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                    }}
                >
                    IncBot
                </div>
            </div>
        </div>
    );
};

export default PracticeVideoView;
