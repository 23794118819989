import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import RecordRTC from 'recordrtc';
import { v4 as uuid } from 'uuid';
import {
    selectConnectionQualityByPeerID,
    selectLocalPeer,
    useAVToggle,
    useHMSActions,
    useHMSStore,
} from '@100mslive/react-sdk';
import { Box, Flex } from '@100mslive/roomkit-react';
import PreviewContainer from './Preview/PreviewContainer';
import { candidateCallStart, uploadVideo, verifyRoom } from '../redux/action';
import { Header } from './Header';
import { useSetUiSettings, useTokenEndpoint } from './AppData/useUISettings';
import getToken from '../services/tokenService';
import { isMobileDevice } from '../utils/helper';
import {
    QUERY_PARAM_AUTH_TOKEN,
    QUERY_PARAM_PREVIEW_AS_ROLE,
    QUERY_PARAM_SKIP_PREVIEW,
    QUERY_PARAM_SKIP_PREVIEW_HEADFUL,
    UI_SETTINGS,
} from '../common/constants';
const env = 'prod';
const PreviewScreen = React.memo(({ authTokenByRoomCodeEndpoint }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const hmsActions = useHMSActions();
    const tokenEndpoint = useTokenEndpoint();
    const [form, setForm] = useState(null);
    const [isMount, setIsMount] = useState(false);
    const [, setIsHeadless] = useSetUiSettings(UI_SETTINGS.isHeadless);
    const { roomId: urlRoomId, role: userRole } = useParams(); // from the url
    const [token, setToken] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [mediaStream, setMediaStream] = useState(null);
    const [isNetworkError, setIsNetworkError] = useState(false);
    const [checkNetworkError, setCheckNetworkError] = useState(true);
    const [permissionsChecks, setPermissionsChecks] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const { isLocalAudioEnabled, isLocalVideoEnabled } = useAVToggle();
    const localPeer = useHMSStore(selectLocalPeer);
    const peerId = localPeer?.id;
    const downlinkQuality = useHMSStore(selectConnectionQualityByPeerID(peerId))?.downlinkQuality;
    // way to skip preview for automated tests, beam recording and streaming
    const beamInToken = useSearchParam('token') === 'beam_recording'; // old format to remove
    let skipPreview = useSearchParam(QUERY_PARAM_SKIP_PREVIEW) === 'true';
    // use this field to join directly for quick testing while in local
    const directJoinHeadFulFromEnv = false;
    const directJoinHeadFul = useSearchParam(QUERY_PARAM_SKIP_PREVIEW_HEADFUL) === 'true' || directJoinHeadFulFromEnv;
    skipPreview = skipPreview || beamInToken || directJoinHeadFul;
    const previewAsRole = useSearchParam(QUERY_PARAM_PREVIEW_AS_ROLE);
    let authToken = useSearchParam(QUERY_PARAM_AUTH_TOKEN);

    const startRecording = async () => {
        try {
            const stream = await navigator?.mediaDevices?.getUserMedia({ audio: true, video: true });
            if (stream) {
                setMediaStream(stream);
                const mediaRecorder = isMobileDevice()
                    ? new RecordRTC(stream, {
                          type: 'audio',
                          mimeType: 'audio/wav', // Adjust the MIME type based on your needs
                      })
                    : new RecordRTC(stream, {
                          type: 'video',
                          mimeType: 'video/webm', // Adjust the MIME type based on your needs
                      });
                mediaRecorder?.startRecording();
                setMediaRecorder(mediaRecorder);
            }
        } catch (error) {
            console.error('Error starting recording:', error);
        }
    };

    const stopRecording = async () => {
        if (mediaRecorder) {
            try {
                mediaRecorder?.stopRecording(async () => {
                    const blob = mediaRecorder?.getBlob();
                    const formData = new FormData();
                    const fileName = isMobileDevice() ? 'recorded-audio.wav' : 'recorded-video.webm';
                    formData.append('roomId', urlRoomId);
                    formData.append('video', blob, fileName);
                    dispatch(uploadVideo(formData));
                    mediaRecorder?.clearRecordedData();
                    if (mediaStream) {
                        const tracks = mediaStream.getTracks();
                        tracks.forEach((track) => track.stop());
                    }
                });
            } catch (error) {
                console.error('Error stopping recording:', error);
            }
        }
    };

    useEffect(() => {
        if (checkNetworkError && downlinkQuality) {
            if (downlinkQuality <= 3) {
                setIsNetworkError(true);
            } else {
                setIsNetworkError(false);
            }
        }
    }, [downlinkQuality]);

    useEffect(() => {
        if (downlinkQuality > 3 && isLocalAudioEnabled && isLocalVideoEnabled) {
            setPermissionsChecks(true);
        } else {
            setPermissionsChecks(false);
        }
    }, [downlinkQuality, isLocalAudioEnabled, isLocalVideoEnabled]);

    useEffect(() => {
        if (authToken) {
            setToken(authToken);
            return;
        }
        if (!tokenEndpoint || !urlRoomId) {
            return;
        }
        const roomCode = !userRole && urlRoomId;

        const getTokenFn = roomCode
            ? () => hmsActions.getAuthTokenByRoomCode({ roomCode }, { endpoint: authTokenByRoomCodeEndpoint })
            : () => getToken(tokenEndpoint, uuid(), userRole, urlRoomId);

        getTokenFn()
            .then((token) => {
                setToken(token);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [hmsActions, tokenEndpoint, urlRoomId, userRole, authToken, authTokenByRoomCodeEndpoint]);

    const onJoin = async () => {
        // Mark the function as async
        !directJoinHeadFul && setIsHeadless(skipPreview);
        if (userRole === 'candidate') {
            dispatch(
                candidateCallStart({
                    roomId: urlRoomId,
                    callback: (status) => {
                        if (status) {
                            stopRecording();
                            let meetingURL = `/meeting/${userRole}/${urlRoomId}`;
                            navigate(meetingURL, { state: form });
                        } else {
                            setIsButtonDisabled(true);
                        }
                    },
                })
            );
        } else {
            let meetingURL = `/meeting/${userRole}/${urlRoomId}`;
            navigate(meetingURL, { state: form });
        }
    };

    const roomVerifyStatus = useSelector((state) => state.Interview.roomVerifyStatus);
    useEffect(() => {
        if (roomVerifyStatus) {
            setForm((prevState) => ({
                ...prevState,
                interviewId: roomVerifyStatus?._id,
                candidateName: roomVerifyStatus?.candidate?.name,
                jobDescription: roomVerifyStatus?.position?.jobDescription,
                positionTitle: roomVerifyStatus?.position?.title,
                companyName: roomVerifyStatus?.companyName,
                candidateEmail: roomVerifyStatus?.candidate?.email,
                candidatePhone: roomVerifyStatus?.candidate?.phone,
                totalDuration: roomVerifyStatus?.question?.totalDuration,
                totalQuestion: roomVerifyStatus?.question?.totalQuestion,
                questionId: roomVerifyStatus?.question?._id,
            }));
        }
    }, [roomVerifyStatus]);
    useEffect(() => {
        if (location) {
            if (location?.state) {
                setForm(location?.state);
            } else {
                dispatch(verifyRoom({ roomId: urlRoomId }));
            }
        }
    }, [dispatch, location, navigate, urlRoomId]);

    useEffect(() => {
        if (form?.interviewId && userRole === 'candidate') {
            if (!(form?.otpVerified && form?.instructionAccepted)) {
                let meetingURL = `/interview-room/${userRole}/${urlRoomId}`;
                navigate(meetingURL);
            }
        }
        if (roomVerifyStatus && !roomVerifyStatus?.status && userRole === 'candidate') {
            let meetingURL = `/interview-room/${userRole}/${urlRoomId}`;
            navigate(meetingURL);
        }
    }, [form, userRole, urlRoomId, navigate]);

    useEffect(() => {
        if (userRole === 'candidate') {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://embed.tawk.to/5d691045eb1a6b0be60a2721/default';
            script.charset = 'UTF-8';
            script.setAttribute('crossorigin', '*');
            script.id = 'tawkToScript';
            document.head.appendChild(script);
            return () => {
                document.head.removeChild(script);
            };
        }
    }, []);

    return (
        <Flex
            direction="column"
            css={{ size: '100%', background: 'rgb(25 25 36)' }}
            className={isMount ? 'mounted' : ''}
        >
            {!isMobileDevice() && (
                <Box
                    css={{
                        h: '$18',
                        '@md': {
                            h: '$17',
                            flexShrink: 0,
                        },
                        zIndex: '1',
                        backgroundColor: 'rgb(25, 25, 36)',
                    }}
                    data-testid="header"
                >
                    <Header isPreview={true} />
                </Box>
            )}
            <PreviewContainer
                initialName={form?.candidateName}
                positionTitle={form?.positionTitle}
                companyName={form?.companyName}
                skipPreview={skipPreview}
                form={form}
                env={env}
                onJoin={onJoin}
                token={token}
                asRole={previewAsRole}
                setIsMount={setIsMount}
                isNetworkError={isNetworkError}
                permissionsChecks={permissionsChecks}
                downlinkQuality={downlinkQuality}
                mediaRecorder={mediaRecorder}
                startRecording={startRecording}
                setCheckNetworkError={setCheckNetworkError}
                isButtonDisabled={isButtonDisabled}
            />
        </Flex>
    );
});

export default PreviewScreen;
