import { Flex, Text } from '@100mslive/roomkit-react';
const DesktopProgressPage = ({ timer }) => (
    <Flex direction="column" css={{ height: '100vh', width: '100vw', background: '#191924' }}>
        <Flex
            justify="center"
            direction="column"
            align="center"
            css={{ bg: '#191924', flex: '1 1 0', position: 'relative' }}
        >
            <Text
                variant="body1"
                css={{
                    color: '#ffffff',
                    fontWeight: '$regular',
                    'font-size': '30px',
                    'line-height': '41px',
                    textAlign: 'center',
                    '@sm': { 'font-size': '20px', 'line-height': '26px' },
                }}
            >
                You will enter Interview room in...
            </Text>
            <br />
            <Text
                variant="body1"
                css={{
                    color: '#ffffff',
                    fontWeight: '800',
                    'font-size': '70px',
                    'line-height': '80px',
                    textAlign: 'center',
                    '@sm': { 'font-size': '50px', 'line-height': '60px' },
                }}
            >
                {timer}
            </Text>
        </Flex>
    </Flex>
);

export default DesktopProgressPage;
