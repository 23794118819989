import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import RecordRTC from 'recordrtc';
import { selectRecordingState, useHMSActions, useHMSStore } from '@100mslive/react-sdk';
import DesktopProgressPage from '../components/DesktopProgressPage';
import FullPageProgress from '../components/FullPageProgress';
import { NetworkConnectionLost } from '../components/Pages/components/NetworkConnectionLost';
import TabSwitch from '../components/Pages/components/portal/TabSwitch';
import VideoStream from '../components/Pages/components/portal/VideoStream';
import { ToastManager } from '../components/Toast/ToastManager';
import {
    candidateCallEnd,
    fetchScreenShotImage,
    getQuestion,
    lookingForward,
    movementRequest,
    notificationError,
    postInterviewStatus,
    resetCodeSubmissionOutput,
    submitCodingQuestion,
    submitTranscribe,
} from '../redux/action';
import { getCodeSubmissionOutput } from '../redux/reducer/interviewReducer';
import { ConferenceMainView } from './mainView';
import MobileMainView from './mobileMainView';
import { getDecodedOutput } from '../common/utils';
import { generateUrl, getBrowserInfoMessage, isMobileDevice, prepareTimeOverLink } from '../utils/helper';
import { DEFAULT_FORM, QUESTION_TYPES, RTMP_RECORD_DEFAULT_RESOLUTION } from '../common/constants';
export const MainViewConnector = ({
    form,
    socket,
    isPlaying,
    setIsPlaying,
    prepareTime,
    setPrepareTime,
    answerTime,
    setAnswerTime,
    displayInput,
    setDisplayInput,
    popupInputs,
    setPopupInputs,
    questionNumber,
    toggleControls,
    candidatePeer,
    setIsSubmitModalOpen,
    isSubmitModalOpen,
}) => {
    const dispatch = useDispatch();
    const hmsActions = useHMSActions();
    const codeOutput = useSelector(getCodeSubmissionOutput);
    const recordingState = useHMSStore(selectRecordingState);
    const { roomId, role } = useParams();
    const [timeoutId, setTimeoutId] = useState(null);
    const [isNetworkError, setIsNetworkError] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [confirmLeave, setConfirmLeave] = useState(false);
    const [recording, setRecording] = useState(false);
    const [mediaStream, setMediaStream] = useState(null);
    const [audioPlayer, setAudioPlayer] = useState();
    const [interviewStarted, setInterviewStarted] = useState(false);
    const [onlineStatus, setOnlineStatus] = useState(true);
    const [recorder, setRecorder] = useState(null);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [timer, setTimer] = useState(5);
    const [resolution, setResolution] = useState(RTMP_RECORD_DEFAULT_RESOLUTION);
    const [answerDuration, setAnswerDuration] = useState(0);
    const [triggered, setTriggered] = useState(false);
    const [recordingTriggered, setRecordingTriggered] = useState(false);
    const [waitingPrepTime, setWaitingPrepTime] = useState(false);
    const [waitingAnswerTime, setWaitingAnswerTime] = useState(false);
    const [isQuestionTypeCoding, setIsQuestionTypeCoding] = useState(false);
    const [questionInfo, setQuestionInfo] = useState({
        question: '',
        questionAnswer: '',
        prepareTime: 0,
        timeToAnswer: 0,
        questionNumber: 1,
        actualQuestion: '',
        domain: '',
        questionType: '',
        audioUrl: '',
    });
    const [eyeMovement, setEyeMovement] = useState({
        time: 0,
        uri: '',
        date: '',
    });
    const [faceCount, setFaceCount] = useState({
        time: 0,
        uri: '',
        date: '',
    });
    const [deviceCount, setDeviceCount] = useState({
        time: 0,
        uri: '',
        date: '',
    });
    const [questionInput, setQuestionInput] = useState({
        interviewId: form?.interviewId,
        questionId: form?.questionId,
        questionNumber: questionNumber,
    });
    const [codeEditorForm, setCodeEditorForm] = useState(DEFAULT_FORM);
    const [hasAnythingChangedInCodeEditor, setHasAnythingChangedInCodeEditor] = useState(false);

    const updateSocket = (type, value) => {
        socket?.emit('codeWritten', { type, value }, roomId);
    };

    useEffect(() => {
        const countdown = setInterval(() => {
            if (timer > 0) setTimer(timer - 1);
            else clearInterval(countdown);
        }, 1000);
        return () => clearInterval(countdown); // Cleanup the interval on component unmount
    }, [timer]);

    useEffect(() => {
        setQuestionInput({
            ...questionInput,
            questionNumber: questionNumber + 1,
        });
    }, [questionNumber]);

    useEffect(() => {
        const handleRecordingSubmission = async () => {
            if (!recordingTriggered && !recordingState?.browser?.running) {
                await handleRecordingOn();
                setRecordingTriggered(true);
            }
        };
        handleRecordingSubmission();
    }, []);

    useEffect(() => {
        const handleSubmission = async () => {
            if (!triggered && recordingState?.browser?.running && questionInput?.questionNumber) {
                await handleSubmit(isMobileDevice() ? 'Mobile' : 'Mobile');
                setTriggered(true);
            }
        };
        handleSubmission();
    }, [recordingState?.browser?.running, questionInput?.questionNumber]);

    const handleEndCall = async (isNetworkDisconnect = false, endCallStatus) => {
        try {
            if (recording && !isQuestionTypeCoding) await stopRecording();
            if (isQuestionTypeCoding) saveCodingQuestion({ data: codeOutput });
            setConfirmLeave(true);
            // Rest of your code in handleEndCall
            navigator?.mediaDevices
                ?.enumerateDevices()
                .then((devices) => {
                    const cameras = devices.filter((device) => device.kind === 'videoinput');
                    cameras.forEach((device) => {
                        navigator?.mediaDevices
                            ?.getUserMedia({ video: { deviceId: device.deviceId } })
                            .then((stream) => {
                                const tracks = stream.getTracks();
                                tracks.forEach((track) => track.stop());
                            })
                            .catch((error) => {
                                console.error('Error stopping camera stream:', error);
                                handleRecordingError(error);
                            });
                    });
                })
                .catch((error) => {
                    console.error('Error fetching media devices:', error);
                    handleRecordingError(error);
                });
            if (recordingState?.browser?.running) handleRecordingOff();
            const doFinally = () => {
                hmsActions.leave();
                ToastManager.clearAllToast();
                window.location.href = `/leave/${role}/${roomId}`;
            };
            if (onlineStatus) {
                if (isNetworkDisconnect)
                    dispatch(postInterviewStatus({ roomId: roomId, status: 'Network Disconnected', doFinally }));
                else
                    dispatch(
                        candidateCallEnd({
                            roomId: roomId,
                            endCallStatus: endCallStatus,
                            doFinally,
                        })
                    );
            }
        } catch (error) {
            console.error('Error in handleEndCall:', error);
            handleRecordingError(error);
        }
    };

    const handleBrowserEndCall = () => {
        try {
            const doFinally = () => {};
            dispatch(
                candidateCallEnd({
                    roomId: roomId,
                    endCallStatus: 'Browser Closed',
                    doFinally,
                })
            );
            if (recordingState?.browser?.running) hmsActions.stopRTMPAndRecording();
        } catch (error) {
            console.log(error);
        }
    };

    const startRecording = async () => {
        try {
            console.log('Recording started for question number : ', questionInfo?.questionNumber);
            const stream = await navigator?.mediaDevices?.getUserMedia({ audio: true });
            if (stream) {
                setMediaStream(stream);
                const mediaRecorder = new RecordRTC(stream, {
                    type: 'audio',
                    mimeType: 'audio/wav', // Adjust the MIME type based on your needs
                });
                mediaRecorder.startRecording();
                setRecorder(mediaRecorder);
                setRecording(true);
            } else {
                console.error('Failed to get user media.');
            }
        } catch (error) {
            console.error('Error starting recording:', error);
        }
    };

    const stopRecording = async () => {
        if (recorder) {
            try {
                console.log('Recording stopped for question number : ', questionInfo?.questionNumber);
                recorder.stopRecording(async () => {
                    const blob = recorder.getBlob();
                    console.log('Blob size:', blob?.size, 'bytes');
                    const formData = new FormData();
                    formData.append('video', blob, 'recorded-audio.wav');
                    formData.append('interviewId', form?.interviewId);
                    formData.append('question', questionInfo?.actualQuestion);
                    formData.append('questionType', questionInfo?.questionType);
                    formData.append('positionTitle', form?.positionTitle);
                    formData.append('answerTimeTaken', questionInfo?.timeToAnswer);
                    formData.append('domain', questionInfo?.domain);
                    formData.append(
                        'timeTaken',
                        questionInfo?.timeToAnswer > answerDuration ? answerDuration : questionInfo?.timeToAnswer
                    );
                    formData.forEach((value, key) => {
                        console.log(`${key}:`, value);
                    });
                    dispatch(submitTranscribe(formData));
                    recorder.clearRecordedData();
                    if (mediaStream) {
                        const tracks = mediaStream.getTracks();
                        tracks.forEach((track) => track.stop());
                    }
                    setRecording(false);
                });
            } catch (error) {
                console.error('Error stopping recording:', error);
                handleRecordingError(error);
            }
        }
    };

    useEffect(() => {
        if (timeoutId) clearTimeout(timeoutId);
        const newTimeoutId = setTimeout(async () => {
            if (!onlineStatus) {
                if (recording && !isQuestionTypeCoding) stopRecording();
                socket?.close();
                if (isPlaying) {
                    if (audioPlayer) {
                        audioPlayer.pause();
                        audioPlayer.close();
                    }
                    setIsPlaying(false);
                    setWaitingPrepTime(false);
                    setWaitingAnswerTime(false);
                    updateSocket('isPlaying', false);
                }
                setIsNetworkError(true);
            }
        }, 10000);
        setTimeoutId(newTimeoutId);
    }, [onlineStatus]);

    const handleRecordingOn = async () => {
        try {
            await hmsActions.startRTMPOrRecording({
                meetingURL: `${generateUrl('meeting')}/preview/beam/${roomId}?skip_preview=true`,
                resolution: setResolution(resolution),
                record: true,
            });
        } catch (error) {
            console.error('Error starting RTMP or recording:', error);
        }
    };

    const handleRecordingOff = async () => {
        try {
            await hmsActions.stopRTMPAndRecording();
        } catch (error) {
            console.error('Error stopping RTMP and recording:', error);
        }
    };

    const handleRecordingError = (error) => {
        let newMessage = `${getBrowserInfoMessage()} Message: ${error.message} Room Id: ${roomId}`;
        dispatch(notificationError({ message: newMessage }));
    };

    const saveMovement = (currentCount, msg, dataURI, currentDate) => {
        if (isValidDataURI(dataURI)) {
            dispatch(
                lookingForward({
                    image: dataURI,
                    callback: (isLookingForward) => {
                        if (!isLookingForward) fetchAndSaveImage(dataURI, currentCount, msg, currentDate);
                    },
                })
            );
        }
    };

    const isValidDataURI = (dataURI) => {
        return dataURI.length > 10 && !dataURI.includes('png');
    };

    const fetchAndSaveImage = (dataURI, currentCount, msg, currentDate) => {
        if (onlineStatus)
            dispatch(
                fetchScreenShotImage({
                    image: dataURI,
                    interviewId: roomId,
                    type: 10,
                    callback: (image) => {
                        if (!image.includes('png')) {
                            makeMovementRequest(image, currentCount, msg, currentDate);
                        }
                    },
                })
            );
    };

    const makeMovementRequest = (image, currentCount, msg, currentDate) => {
        if (onlineStatus)
            dispatch(
                movementRequest({
                    roomId: roomId,
                    interviewId: form?.interviewId,
                    message: msg,
                    image: image,
                    dateAndTime: currentDate,
                    duration: currentCount,
                })
            );
    };

    const offlineCallback = () => {
        setOnlineStatus(false);
        socket?.disconnect();
    };

    const onlineCallback = () => {
        setOnlineStatus(true);
        socket?.connect();
    };

    useEffect(() => {
        window.addEventListener('offline', offlineCallback);
        window.addEventListener('online', onlineCallback);
        return () => {
            window.removeEventListener('offline', offlineCallback);
            window.removeEventListener('online', onlineCallback);
        };
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (!confirmLeave) {
                e.preventDefault();
                e.returnValue = '';
                return 'Are you sure you want to leave? Your task may not be saved.';
            }
        };
        const handleUnload = () => {
            if (!confirmLeave) handleBrowserEndCall();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('unload', handleUnload);
        window.addEventListener('popstate', handleUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('unload', handleUnload);
            window.removeEventListener('popstate', handleUnload);
        };
    }, [confirmLeave]);

    const timerEnd = () => {
        setPrepareTime(0);
        updateSocket('prepareTime', 0);
        setAnswerTime(0);
        updateSocket('answerTime', 0);
    };

    const saveCodingQuestion = ({ data }) => {
        const result = data ?? codeOutput;
        dispatch(
            submitCodingQuestion({
                data: {
                    memory: result?.memory || 0,
                    runTime: result?.time || 0,
                    finalResult: getDecodedOutput(result) || '',
                    programmingLanguage: result?.language?.name || codeEditorForm?.programmingLanguage,
                    interviewId: form?.interviewId,
                    question: questionInfo?.actualQuestion,
                    timeTaken:
                        questionInfo?.timeToAnswer > answerDuration ? answerDuration : questionInfo?.timeToAnswer,
                    questionType: questionInfo?.questionType,
                    languageCode: codeEditorForm?.language || '',
                    code: codeEditorForm?.codeEditor || '',
                    prebuilt: codeEditorForm?.prebuilt || '',
                    questionLevel: codeEditorForm?.difficultyLevel,
                    questionId: codeEditorForm?.questionId,
                },
            })
        );
        dispatch(resetCodeSubmissionOutput());
        if (hasAnythingChangedInCodeEditor) setHasAnythingChangedInCodeEditor(false);
    };

    const handleSubmit = async (mode) => {
        try {
            setWaitingPrepTime(true);
            if (recording && !isQuestionTypeCoding) await stopRecording();
            if (isQuestionTypeCoding) saveCodingQuestion({ data: codeOutput });
            if (onlineStatus)
                dispatch(
                    getQuestion({
                        mode: mode,
                        data: questionInput,
                        resumedQuestion: !triggered && questionNumber > 0,
                        callback: (data) => {
                            setQuestionInfo((prevState) => ({
                                ...prevState,
                                question: data?.response,
                                questionAnswer: data?.questionAnswer,
                                prepareTime: data?.timeToPrepare,
                                timeToAnswer: data?.timeToAnswer,
                                questionNumber: questionInput?.questionNumber,
                                actualQuestion: data?.question,
                                domain: data?.domain,
                                questionType: data?.questionType,
                                audioUrl: data?.audioUrl ?? '',
                            }));
                            if (data?.questionType === QUESTION_TYPES.CODING) {
                                const codingForm = {
                                    ...DEFAULT_FORM,
                                    preBuiltFunction: data?.preBuiltFunction,
                                    difficultyLevel: data?.difficultyLevel,
                                    questionId: data?.questionId,
                                    neededLanguage: data?.programmingLanguage,
                                };
                                setCodeEditorForm(codingForm);
                                updateSocket('codeEditorForm', codingForm);
                                setIsQuestionTypeCoding(true);
                            } else {
                                setIsQuestionTypeCoding(false);
                            }
                            const currentQuestionNumber = parseInt(questionInput?.questionNumber);
                            const totalQuestions = parseInt(form?.totalQuestion);
                            let questionStatus = currentQuestionNumber <= totalQuestions;
                            if (questionStatus) {
                                // If there are more questions, update prepare time, answer time, and question number
                                setPrepareTime(data?.timeToPrepare);
                                updateSocket('prepareTime', data?.timeToPrepare);
                                setAnswerTime(data?.timeToAnswer);
                                updateSocket('answerTime', data?.timeToAnswer);
                                if (!interviewStarted) {
                                    setInterviewStarted(true);
                                }
                                setQuestionInput((prevState) => ({
                                    ...prevState,
                                    candidateResponse: '',
                                    questionNumber: currentQuestionNumber + 1,
                                }));
                            } else {
                                timerEnd();
                            }
                            if (mode === 'Mobile') {
                                const pValue = {
                                    ...popupInputs,
                                    questionNumber: currentQuestionNumber + 1,
                                    totalQuestions: totalQuestions,
                                    questionType: data?.questionType,
                                };
                                setPopupInputs(pValue);
                                updateSocket('popupInputs', pValue);
                            }
                            const value = {
                                message: data?.response,
                                displayText:
                                    `Question-${currentQuestionNumber}: ${data?.question}` ??
                                    `Question-${currentQuestionNumber}: ${data?.response}`,
                                audio: data?.audioUrl ?? '',
                                codeBlock: '',
                            };
                            if (data?.codeBlock) value['codeBlock'] = data?.codeBlock;
                            setDisplayInput(value);
                            updateSocket('displayInput', value);
                            setIsUpdated(true);
                            setAnswerDuration(0);
                        },
                    })
                );
        } catch (error) {
            handleRecordingError(error);
        }
    };

    const handleAudioEnd = async () => {
        setIsPlaying(false);
        setWaitingPrepTime(false);
        setWaitingAnswerTime(false);
        updateSocket('isPlaying', false);
        if (displayInput?.audio === prepareTimeOverLink && !recording && !isQuestionTypeCoding) {
            console.log('Recording Started');
            await startRecording();
        }
        if (audioPlayer) {
            audioPlayer.removeEventListener('ended', handleAudioEnd);
            audioPlayer.removeEventListener('error', handleAudioError);
        }
    };

    const handleAudioError = (error) => {
        setIsPlaying(false);
        setWaitingPrepTime(false);
        setWaitingAnswerTime(false);
        updateSocket('isPlaying', false);
        console.log('Audio playback error:', error);
        handleRecordingError(error);
        if (audioPlayer) {
            audioPlayer.removeEventListener('ended', handleAudioEnd);
            audioPlayer.removeEventListener('error', handleAudioError);
        }
    };

    const playAudio = async () => {
        const audio = new Audio(displayInput?.audio);
        setAudioPlayer(audio);
        audio.addEventListener('ended', handleAudioEnd);
        audio.addEventListener('error', handleAudioError);
        try {
            await audio.play();
            setIsPlaying(true);
            updateSocket('isPlaying', true);
        } catch (error) {
            handleAudioError(error);
        }
        // Add event listener to stop audio on page unload
        window.addEventListener('beforeunload', () => {
            audio.pause();
            audio.currentTime = 0;
        });
        // Add event listener to stop audio on history change (back button)
        window.addEventListener('popstate', () => {
            audio.pause();
            audio.currentTime = 0;
        });
    };

    useEffect(() => {
        if (audioPlayer) {
            const interval = setInterval(() => {
                if (audioPlayer.paused) {
                    clearInterval(interval);
                    setIsPlaying(false);
                    setWaitingPrepTime(false);
                    setWaitingAnswerTime(false);
                    updateSocket('isPlaying', false);
                } else if (!isPlaying) {
                    setIsPlaying(true);
                    updateSocket('isPlaying', true);
                }
            }, 100);

            // Cleanup interval on component unmount or when audioPlayer changes
            return () => clearInterval(interval);
        }
    }, [audioPlayer, isPlaying]);

    const skipTimer = async () => {
        if (!isQuestionTypeCoding) await startRecording();
        setPrepareTime(0);
        updateSocket('prepareTime', 0);
    };

    useEffect(() => {
        if (onlineStatus && isNetworkError) handleEndCall(true, 'Network Disconnected');
    }, [onlineStatus, isNetworkError]);

    useEffect(() => {
        if (isQuestionTypeCoding) setPosition({ x: 0, y: 0 });
    }, [questionInfo?.actualQuestion]);

    return timer > 0 ? (
        <DesktopProgressPage timer={timer} />
    ) : !isUpdated && !recordingState?.browser?.running ? (
        <FullPageProgress />
    ) : isNetworkError ? (
        <NetworkConnectionLost inInterviewRoom />
    ) : (
        <>
            {isMobileDevice() ? (
                <MobileMainView
                    form={form}
                    roomId={roomId}
                    toggleControls={toggleControls}
                    questionInfo={questionInfo}
                    isPlaying={isPlaying}
                    prepareTime={prepareTime}
                    setPrepareTime={setPrepareTime}
                    answerTime={answerTime}
                    setAnswerTime={setAnswerTime}
                    socket={socket}
                    handleEndCall={handleEndCall}
                    recording={recording}
                    interviewStarted={interviewStarted}
                    onlineStatus={onlineStatus}
                    setAnswerDuration={setAnswerDuration}
                    answerDuration={answerDuration}
                    handleSubmit={handleSubmit}
                    candidatePeer={candidatePeer}
                    displayInput={displayInput}
                    popupInputs={popupInputs}
                    setPopupInputs={setPopupInputs}
                    startRecording={startRecording}
                    playAudio={playAudio}
                    skipTimer={skipTimer}
                    waitingPrepTime={waitingPrepTime}
                    setDisplayInput={setDisplayInput}
                    updateSocket={updateSocket}
                />
            ) : (
                <ConferenceMainView
                    form={form}
                    toggleControls={toggleControls}
                    questionInfo={questionInfo}
                    isPlaying={isPlaying}
                    prepareTime={prepareTime}
                    setPrepareTime={setPrepareTime}
                    answerTime={answerTime}
                    setAnswerTime={setAnswerTime}
                    handleEndCall={handleEndCall}
                    recording={recording}
                    interviewStarted={interviewStarted}
                    onlineStatus={onlineStatus}
                    setAnswerDuration={setAnswerDuration}
                    answerDuration={answerDuration}
                    handleSubmit={handleSubmit}
                    candidatePeer={candidatePeer}
                    displayInput={displayInput}
                    setDisplayInput={setDisplayInput}
                    isUpdated={isUpdated}
                    playAudio={playAudio}
                    skipTimer={skipTimer}
                    waitingPrepTime={waitingPrepTime}
                    waitingAnswerTime={waitingAnswerTime}
                    setWaitingAnswerTime={setWaitingAnswerTime}
                    startRecording={startRecording}
                    timerEnd={timerEnd}
                    updateSocket={updateSocket}
                    setCodeEditorForm={setCodeEditorForm}
                    codeEditorForm={codeEditorForm}
                    hasAnythingChangedInCodeEditor={hasAnythingChangedInCodeEditor}
                    setHasAnythingChangedInCodeEditor={setHasAnythingChangedInCodeEditor}
                    isQuestionTypeCoding={isQuestionTypeCoding}
                    setIsSubmitModalOpen={setIsSubmitModalOpen}
                    isSubmitModalOpen={isSubmitModalOpen}
                    position={position}
                    setPosition={setPosition}
                />
            )}
            <VideoStream
                interviewId={form?.interviewId}
                roomId={roomId}
                eyeMovement={eyeMovement}
                faceCount={faceCount}
                deviceCount={deviceCount}
                setEyeMovement={setEyeMovement}
                setFaceCount={setFaceCount}
                setDeviceCount={setDeviceCount}
                saveMovement={saveMovement}
                lookingTimer={isQuestionTypeCoding ? 90 : 15}
            />
            <TabSwitch interviewId={form?.interviewId} />
        </>
    );
};
