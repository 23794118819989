import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { resendOtpRequest } from '../../../../redux/action';
import MainPage from './MainPage';
import Button from '../../../commonComponent/Button';

const Verify = ({ form, setForm, handleSubmit, isDisabled }) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [resendDisabled, setResendDisabled] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        let interval;
        if (resendDisabled) {
            interval = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown === 1) {
                        clearInterval(interval);
                        setResendDisabled(false);
                        return prevCountdown;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [resendDisabled]);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleChange = (otp) => {
        setForm((prevState) => ({
            ...prevState,
            otp: otp,
        }));
    };

    const handleResendClick = () => {
        if (!resendDisabled) {
            setResendDisabled(true);
            setCountdown(30);
            dispatch(resendOtpRequest({ interviewId: form?.interviewId }));
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <MainPage>
            <>
                <h4 className="title">Welcome</h4>
                <p className="sub-title mt-4 text-center">Please verify before starting</p>
                <div className="title-line"></div>
                <div className="content-container verify-content-container">
                    <div className="info-section mt-lg-5 mt-3 px-lg-4 px-0">
                        <span className="fw-8">Hello {form?.candidateName}!</span>
                        <br />
                        <span className="fw-6">
                            Welcome to the Interview for <span className="fw-7">{form?.companyName}</span>!
                        </span>
                        <br />
                        <span className="fw-7">
                            Job Position:{' '}
                            <span className="blue-text">
                                <a
                                    href={form?.jobDescription}
                                    title={form?.positionTitle}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {form?.positionTitle}
                                </a>
                            </span>
                        </span>
                        <br />
                        <span className="fw-7">Email Id: </span>
                        <span>{form?.candidateEmail}</span>
                        <br />
                        <span className="fw-7">Whatsapp No.: </span>
                        <span>+{form?.candidatePhone}</span>
                    </div>
                    <div className="otp-section mt-lg-4 mt-4 px-4">
                        <h3 className="otp-title">Enter OTP received on Email ID or Whatsapp no.</h3>
                        <div className="otp-input-container" onKeyDown={handleKeyDown}>
                            <div className="verification-otp-div">
                                <OtpInput
                                    disabledStyle={false}
                                    value={form?.otp}
                                    inputType="number"
                                    onChange={handleChange}
                                    separator={<span style={{ width: '12px' }}></span>}
                                    numInputs={4}
                                    renderInput={(props) => <input {...props} />}
                                    shouldAutoFocus={true}
                                    inputStyle={
                                        screenWidth > 992
                                            ? {
                                                  borderRight: 'none',
                                                  borderLeft: 'none',
                                                  borderTop: 'none',
                                                  borderBottom: '1px solid #000000',
                                                  backgroundColor: 'initial',
                                                  boxShadow: '#191924',
                                                  width: '54px',
                                                  height: '54px',
                                                  fontSize: '18px',
                                                  color: '#000000',
                                                  fontWeight: '400',
                                                  caretColor: '#000000',
                                              }
                                            : {
                                                  borderBottom: '1px solid #c2cad3',
                                                  backgroundColor: 'rgb(46, 46, 65)',
                                                  borderRadius: 'unset',
                                                  boxShadow: '#191924',
                                                  width: '40px',
                                                  height: '51px',
                                                  fontSize: '24px',
                                                  color: '#ffffff',
                                                  fontWeight: '400',
                                                  caretColor: 'white',
                                              }
                                    }
                                    focusStyle={{
                                        borderBottom: screenWidth > 992 ? '1px solid #000000' : '1px solid #ffffff',
                                        outline: 'none',
                                        borderRight: 'none',
                                        borderLeft: 'none',
                                        borderTop: 'none',
                                    }}
                                />
                            </div>
                        </div>
                        <center>
                            <button
                                onClick={handleResendClick}
                                disabled={resendDisabled}
                                className={`blue-text text-underline fw-5 mt-3 text-center f-12${
                                    resendDisabled ? ' disabled-text' : ''
                                }`}
                            >
                                Resend code{countdown > 1 ? ` (0:${countdown})` : ''}
                            </button>
                        </center>
                    </div>
                    <Button
                        text="Verify"
                        className="common-btn mt-lg-5 mt-3 mx-auto"
                        onClick={debounce(handleSubmit, 300)}
                        disabled={isDisabled}
                    />
                </div>
            </>
        </MainPage>
    );
};

export default Verify;
