import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { Button } from '@100mslive/roomkit-react';
import FaceNotFoundSvg from '../../../../assets/images/face-not-found.svg';
import practiceActiveVector from '../../../../assets/images/practice-active-vector.svg';
import practiceDisabledVector from '../../../../assets/images/practice-disabled-vector.svg';
import { practiceQuestion, practiceQuestionUpdate } from '../../../../redux/action';
import { ToastManager } from '../../../Toast/ToastManager';
import PreviewTile from './PreviewTile';
import Modal from '../../../commonComponent/Modal/Modal';
import { generateUrl, isMobileDevice } from '../../../../utils/helper';
import { AUDIO_NOT_DETECTED } from '../../../../common/constants';
import { FACE_DETECTED } from '../../utils/constants';
const FaceNotDetectedContent = () => {
    return (
        <div className="face-detection-modal-content">
            <div className="face-detection-modal-content-text1">
                Uh-oh! It looks like your face is not fully visible or not detected by the camera.
            </div>
            <div className="face-detection-modal-content-text2">
                👀 Please ensure that your face is properly positioned and well-lit within the camera frame for a
                starting an interview
            </div>
        </div>
    );
};

const AcknowledgeModal = memo(
    ({
        onJoin,
        name,
        form,
        token,
        previewError,
        value,
        positionTitle,
        companyName,
        videoContainerRef,
        speakingPermission,
        recorder,
        isButtonDisabled,
    }) => {
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const { roomId, role } = useParams();
        const basePath = generateUrl('interview_backend');
        const [isStarting, setIsStarting] = useState(false);
        const [practiceStatus, setPracticeStatus] = useState(false);
        const [hasFullFaceDetected, setHasFullFaceDetected] = useState(FACE_DETECTED.DEFAULT);
        const canvasRef = useRef(null);
        const acknowledgeText = `Hello, my name is ${name}, and I am interviewing for the position of ${positionTitle} at ${companyName}.`;

        useEffect(() => {
            dispatch(
                practiceQuestion({
                    roomId: roomId,
                    callback: (status) => {
                        if (status) {
                            setPracticeStatus(true);
                        }
                    },
                })
            );
        }, [dispatch]);

        const handleText = () => {
            return new Promise((resolve) => {
                debounce(() => {
                    if (value || !speakingPermission) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }, 300)();
            });
        };

        const handlePracticeTest = () => {
            dispatch(
                practiceQuestionUpdate({
                    roomId: roomId,
                })
            );
            const meetingURL = `/practice/room/${role}/${roomId}`;
            navigate(meetingURL, { state: { status: true, form: form, token: token } });
        };

        const handleSubmit = async () => {
            setIsStarting(true);
            const hasTranscribed = await handleText();
            if (hasTranscribed) onJoin();
            else if (recorder) {
                recorder?.stopRecording(async () => {
                    try {
                        const blob = recorder?.getBlob();
                        console.log(blob);
                        if (blob?.size > 0) {
                            const formData = new FormData();
                            formData.append(
                                'video',
                                blob,
                                isMobileDevice() ? 'recorded-audio.wav' : 'recorded-video.webm'
                            );
                            const response = await fetch(`${basePath}/fetchVoiceTranscribe`, {
                                method: 'POST',
                                body: formData,
                            });
                            console.log('fetchVoiceTranscribe', response);
                            if (!response.ok) {
                                throw new Error('Fetch failed');
                            }
                            const data = await response.json();
                            if (data?.result?.transcript) {
                                onJoin();
                                return;
                            } else {
                                throw new Error('Transcript not found');
                            }
                        } else {
                            throw new Error('Blob is empty');
                        }
                    } catch (error) {
                        console.error('Error fetching voice:', error);
                    } finally {
                        setIsStarting(false);
                        recorder?.startRecording();
                        ToastManager.addToast({
                            title: AUDIO_NOT_DETECTED,
                        });
                    }
                });
            }
        };

        const onCloseModal = () => setHasFullFaceDetected(FACE_DETECTED.DEFAULT);
        const practiceStatusFinal = !(isStarting || isButtonDisabled) && practiceStatus;

        return (
            <div className="interview-preview-page" ref={videoContainerRef}>
                <canvas ref={canvasRef} className="d-none" />
                <Modal
                    isOpen={hasFullFaceDetected === FACE_DETECTED.NOT_DETECTED}
                    showCloseButton
                    onClose={onCloseModal}
                    headerText="Face Not Detected!"
                    Content={FaceNotDetectedContent}
                    imgSrc={FaceNotFoundSvg}
                    buttonText="OK"
                    handleClick={onCloseModal}
                />
                <div className="d-flex preview-page-layout">
                    <div className="w-50">
                        <div className="finish-title">Get Started</div>
                        <div className="finish-title-main pb-3 pt-2">Setup your audio and video before joining</div>
                        <PreviewTile name={name} error={previewError} auto />
                    </div>
                    <div className="d-flex flex-column justify-content-end p-4 pb-0 w-50">
                        <div className="finish-title-m pt-2">
                            Please read the line below to test your camera and microphone before starting the interview
                        </div>
                        <div className="dark-bg">
                            <p className="acknowledge_text-m my-auto">{acknowledgeText}</p>
                            <div className="px-2 py-4">
                                <div
                                    className={`${
                                        value
                                            ? 'acknowledge_text_transcript_new'
                                            : 'acknowledge_text_transcript_disable_new'
                                    }`}
                                >
                                    {value ? value : 'Please wait while speech is being transcribed'}
                                </div>
                            </div>
                            <div className="acknowledge_title_line my-2" />
                            <div className="d-flex justify-content-center">
                                <Button
                                    type="submit"
                                    disabled={isStarting || isButtonDisabled}
                                    onClick={handleSubmit}
                                    className={`mx-auto d-flex ${isStarting || isButtonDisabled ? 'start-transcript-check-btn-disabled' : 'start-transcript-check-btn'}`}
                                >
                                    Start Interview
                                </Button>
                            </div>
                        </div>
                        {practiceStatusFinal ? (
                            <div className="practice-test">
                                <span onClick={handlePracticeTest}>
                                    Practice Before Start
                                    <img src={practiceActiveVector} className="practice-vector" alt="Practice Test" />
                                </span>
                            </div>
                        ) : (
                            <div className="practice-test-disabled">
                                Practice Before Start
                                <img src={practiceDisabledVector} className="practice-vector" alt="Practice Test" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
);

export default AcknowledgeModal;
