import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Flex, styled, Text } from '@100mslive/roomkit-react';
import InterviewFeedback from './Pages/components/InterviewFeedback';
import logo from '../images/incruiter-logo.svg';
import { interviewFeedbackStatus } from '../redux/action';
const PostLeave = () => {
    const dispatch = useDispatch();
    const { roomId } = useParams();
    const [isInterviewFeedbackVisible, setIsInterviewFeedbackVisible] = useState(true);
    const [status, setStatus] = useState(true);
    const interviewFeedbackStatusDetails = useSelector((state) => state.Interview.interviewFeedbackStatusDetails);
    const whiteLabelLogos = useSelector((state) => state.Interview.whiteLabelLogos);
    const fetchingWhiteLabelStatus = useSelector((state) => state.Interview.fetchingWhiteLabelStatus);
    useEffect(() => {
        if (roomId) {
            dispatch(interviewFeedbackStatus({ roomId: roomId }));
        }
    }, [dispatch, roomId]);
    useEffect(() => {
        if (interviewFeedbackStatusDetails) {
            setStatus(interviewFeedbackStatusDetails?.status);
        }
    }, [interviewFeedbackStatusDetails]);
    return (
        <Flex direction="column" css={{ height: '100vh', width: '100vw' }}>
            {!status && isInterviewFeedbackVisible && (
                <InterviewFeedback setIsInterviewFeedbackVisible={setIsInterviewFeedbackVisible} roomId={roomId} />
            )}
            <Flex
                justify="center"
                direction="column"
                align="center"
                css={{ bg: '#191924', flex: '1 1 0', position: 'relative' }}
            >
                {!fetchingWhiteLabelStatus && (
                    <LogoImg src={whiteLabelLogos?.whiteLogo || logo} alt="Company Logo" width={'auto'} height={108} />
                )}
                <br />
                <Text
                    variant="body1"
                    css={{
                        color: '#ffffff',
                        fontWeight: '$regular',
                        'font-size': '30px',
                        'line-height': '41px',
                        textAlign: 'center',
                        '@sm': { 'font-size': '24px', 'line-height': '30px' },
                    }}
                >
                    The meeting has been ended.
                </Text>
            </Flex>
        </Flex>
    );
};
const LogoImg = styled('img', {
    p: '$2',
});

export default PostLeave;
