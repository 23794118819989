import { selectIsLocalVideoEnabled, selectLocalPeer, selectVideoTrackByID, useHMSStore } from '@100mslive/react-sdk';
import { Avatar, Loading, StyledVideoTile, Video, useBorderAudioLevel } from '@100mslive/roomkit-react';
import { useUISettings } from '../../../AppData/useUISettings';
import { UI_SETTINGS } from '../../../../common/constants';
import TileConnection from '../../../Connection/TileConnection';
import { VIDEO_ID } from '../../utils/constants';
import PreviewSettings from '../../PreviewSettings';
const PreviewTileM = ({ name, error, auto, isPreview }) => {
    const localPeer = useHMSStore(selectLocalPeer);
    const borderAudioRef = useBorderAudioLevel(localPeer?.audioTrack);
    const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);
    const mirrorLocalVideo = useUISettings(UI_SETTINGS.mirrorLocalVideo);
    const trackSelector = selectVideoTrackByID(localPeer?.videoTrack);
    const track = useHMSStore(trackSelector);

    return (
        <StyledVideoTile.Container
            css={{
                bg: '$surface_default',
                width: 'unset',
                height: auto ? 'auto' : 'min(360px, 50vh)',
                '@md': { maxHeight: '300px' },
                '@sm': {
                    width: 'min(360px, 100%)',
                    maxWidth: '100%',
                    marginTop: '0',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                },
            }}
            ref={borderAudioRef}
        >
            {localPeer ? (
                <>
                    <TileConnection name={localPeer.name} peerId={localPeer.id} isPreview={isPreview} />
                    <Video
                        id={VIDEO_ID}
                        mirror={track?.facingMode !== 'environment' && mirrorLocalVideo}
                        trackId={localPeer.videoTrack}
                        data-testid={VIDEO_ID}
                    />
                    {!isVideoOn ? (
                        <StyledVideoTile.AvatarContainer>
                            <Avatar name={name} data-testid="preview_avatar_tile" />
                            <Text css={{ ...textEllipsis('75%') }} variant="body2">
                                {name}
                            </Text>
                        </StyledVideoTile.AvatarContainer>
                    ) : null}
                    <PreviewSettings />
                </>
            ) : !error ? (
                <Loading size={100} />
            ) : null}
        </StyledVideoTile.Container>
    );
};

export default PreviewTileM;
