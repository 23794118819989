// ============================= INTERVIEW =============================

export const GET_INTERVIEW_TOKEN = 'GET_INTERVIEW_TOKEN';
export const GET_INTERVIEW_TOKEN_SUCCESS = 'GET_INTERVIEW_TOKEN_SUCCESS';
export const GET_INTERVIEW_TOKEN_FAILURE = 'GET_INTERVIEW_TOKEN_FAILURE';

export const GET_INTERVIEW = 'GET_INTERVIEW';
export const GET_INTERVIEW_SUCCESS = 'GET_INTERVIEW_SUCCESS';
export const GET_INTERVIEW_FAILURE = 'GET_INTERVIEW_FAILURE';

export const GET_QUESTION_NUMBER = 'GET_QUESTION_NUMBER';
export const GET_QUESTION_NUMBER_SUCCESS = 'GET_QUESTION_NUMBER_SUCCESS';
export const GET_QUESTION_NUMBER_FAILURE = 'GET_QUESTION_NUMBER_FAILURE';

export const GET_QUESTION = 'GET_QUESTION';
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS';
export const GET_QUESTION_FAILURE = 'GET_QUESTION_FAILURE';

export const POST_INTERVIEW_STATUS = 'POST_INTERVIEW_STATUS';
export const POST_INTERVIEW_STATUS_SUCCESS = 'POST_INTERVIEW_STATUS_SUCCESS';
export const POST_INTERVIEW_STATUS_FAILURE = 'POST_INTERVIEW_STATUS_FAILURE';

export const POST_INTERVIEW_FEEDBACK = 'POST_INTERVIEW_FEEDBACK';
export const POST_INTERVIEW_FEEDBACK_SUCCESS = 'POST_INTERVIEW_FEEDBACK_SUCCESS';
export const POST_INTERVIEW_FEEDBACK_FAILURE = 'POST_INTERVIEW_FEEDBACK_FAILURE';

export const POST_CANDIDATE = 'POST_CANDIDATE';
export const POST_CANDIDATE_SUCCESS = 'POST_CANDIDATE_SUCCESS';
export const POST_CANDIDATE_FAILURE = 'POST_CANDIDATE_FAILURE';

export const RESEND_OTP_REQUEST = 'RESEND_OTP_REQUEST';
export const RESEND_OTP_REQUEST_SUCCESS = 'RESEND_OTP_REQUEST_SUCCESS';
export const RESEND_OTP_REQUEST_FAILURE = 'RESEND_OTP_REQUEST_FAILURE';

export const MOVEMENT = 'MOVEMENT';
export const MOVEMENT_SUCCESS = 'MOVEMENT_SUCCESS';
export const MOVEMENT_FAILURE = 'MOVEMENT_FAILURE';

export const CONTACT_ADMIN = 'CONTACT_ADMIN';
export const CONTACT_ADMIN_SUCCESS = 'CONTACT_ADMIN_SUCCESS';
export const CONTACT_ADMIN_FAILURE = 'CONTACT_ADMIN_FAILURE';

export const CANDIDATE_CALL_START = 'CANDIDATE_CALL_START';
export const CANDIDATE_CALL_START_SUCCESS = 'CANDIDATE_CALL_START_SUCCESS';
export const CANDIDATE_CALL_START_FAILURE = 'CANDIDATE_CALL_START_FAILURE';

export const CANDIDATE_CALL_END = 'CANDIDATE_CALL_END';
export const CANDIDATE_CALL_END_SUCCESS = 'CANDIDATE_CALL_END_SUCCESS';
export const CANDIDATE_CALL_END_FAILURE = 'CANDIDATE_CALL_END_FAILURE';

export const INTERVIEW_FEEDBACK_STATUS = 'INTERVIEW_FEEDBACK_STATUS';
export const INTERVIEW_FEEDBACK_STATUS_SUCCESS = 'INTERVIEW_FEEDBACK_STATUS_SUCCESS';
export const INTERVIEW_FEEDBACK_STATUS_FAILURE = 'INTERVIEW_FEEDBACK_STATUS_FAILURE';

export const VERIFY_CANDIDATE_KEY = 'VERIFY_CANDIDATE_KEY';
export const VERIFY_CANDIDATE_KEY_SUCCESS = 'VERIFY_CANDIDATE_KEY_SUCCESS';
export const VERIFY_CANDIDATE_KEY_FAILURE = 'VERIFY_CANDIDATE_KEY_FAILURE';

export const VERIFY_ROOM = 'VERIFY_ROOM';
export const VERIFY_ROOM_SUCCESS = 'VERIFY_ROOM_SUCCESS';
export const VERIFY_ROOM_FAILURE = 'VERIFY_ROOM_FAILURE';

export const CREATE_AND_UPDATE_SCREENSHOT = 'CREATE_AND_UPDATE_SCREENSHOT';
export const CREATE_AND_UPDATE_SCREENSHOT_SUCCESS = 'CREATE_AND_UPDATE_SCREENSHOT_SUCCESS';
export const CREATE_AND_UPDATE_SCREENSHOT_FAILURE = 'CREATE_AND_UPDATE_SCREENSHOT_FAILURE';

export const REMOVE_SCREENSHOT_IMAGE = 'REMOVE_SCREENSHOT_IMAGE';
export const FETCH_SCREENSHOT_IMAGE = 'FETCH_SCREENSHOT_IMAGE';
export const FETCH_SCREENSHOT_IMAGE_SUCCESS = 'FETCH_SCREENSHOT_IMAGE_SUCCESS';
export const FETCH_SCREENSHOT_IMAGE_FAILURE = 'FETCH_SCREENSHOT_IMAGE_FAILURE';

export const SWITCH_TAB = 'SWITCH_TAB';
export const SWITCH_TAB_SUCCESS = 'SWITCH_TAB_SUCCESS';
export const SWITCH_TAB_FAILURE = 'SWITCH_TAB_FAILURE';

export const SUBMIT_TRANSCRIBE = 'SUBMIT_TRANSCRIBE';
export const SUBMIT_TRANSCRIBE_SUCCESS = 'SUBMIT_TRANSCRIBE_SUCCESS';
export const SUBMIT_TRANSCRIBE_FAILURE = 'SUBMIT_TRANSCRIBE_FAILURE';

export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';
export const NOTIFICATION_ERROR_SUCCESS = 'NOTIFICATION_ERROR_SUCCESS';
export const NOTIFICATION_ERROR_FAILURE = 'NOTIFICATION_ERROR_FAILURE';

export const FULL_FACE_DETECTION = 'FULL_FACE_DETECTION';
export const FULL_FACE_DETECTION_SUCCESS = 'FULL_FACE_DETECTION_SUCCESS';
export const FULL_FACE_DETECTION_FAILURE = 'FULL_FACE_DETECTION_FAILURE';

export const CREATE_CODE_SUBMISSION = 'CREATE_CODE_SUBMISSION';
export const CREATE_CODE_SUBMISSION_SUCCESS = 'CREATE_CODE_SUBMISSION_SUCCESS';
export const CREATE_CODE_SUBMISSION_FAILURE = 'CREATE_CODE_SUBMISSION_FAILURE';
export const RESET_CODE_SUBMISSION_OUTPUT = 'RESET_CODE_SUBMISSION_OUTPUT';

export const SUBMIT_CODING_QUESTION = 'SUBMIT_CODING_QUESTION';
export const SUBMIT_CODING_QUESTION_SUCCESS = 'SUBMIT_CODING_QUESTION_SUCCESS';
export const SUBMIT_CODING_QUESTION_FAILURE = 'SUBMIT_CODING_QUESTION_FAILURE';

export const LOOKING_FORWARD = 'LOOKING_FORWARD';
export const LOOKING_FORWARD_SUCCESS = 'LOOKING_FORWARD_SUCCESS';
export const LOOKING_FORWARD_FAILURE = 'LOOKING_FORWARD_FAILURE';

export const UPLOAD_VIDEO = 'UPLOAD_VIDEO';
export const UPLOAD_VIDEO_SUCCESS = 'UPLOAD_VIDEO_SUCCESS';
export const UPLOAD_VIDEO_FAILURE = 'UPLOAD_VIDEO_FAILURE';

export const PRACTICE_QUESTION = 'PRACTICE_QUESTION';
export const PRACTICE_QUESTION_SUCCESS = 'PRACTICE_QUESTION_SUCCESS';
export const PRACTICE_QUESTION_FAILURE = 'PRACTICE_QUESTION_FAILURE';

export const PRACTICE_QUESTION_UPDATE = 'PRACTICE_QUESTION_UPDATE';
export const PRACTICE_QUESTION_UPDATE_SUCCESS = 'PRACTICE_QUESTION_UPDATE_SUCCESS';
export const PRACTICE_QUESTION_UPDATE_FAILURE = 'PRACTICE_QUESTION_UPDATE_FAILURE';
