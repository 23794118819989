import MainPage from './MainPage';
import Button from '../../../commonComponent/Button';
import { formatTime, generateUrl, getAssetSrc } from '../../../../utils/helper';
import { PRIVACY_POLICY, TERMS_AND_CONDITION } from '../../../../utils/constants';
import { ASSET_NAMES } from '../../../../common/constants';
const Instructions = ({ form, setForm, handleJoin, className, instructions }) => {
    return (
        <MainPage title className={className}>
            <div className="instruction_container">
                <h4 className="title">Interview Instructions</h4>
                <div className="title-line d-lg-none"></div>
                <div className="content-container verify-content-container mt-lg-3 mt-2 instruction_mob_content_container">
                    <div className="d-flex align-items-center justify-content-between instruction_info">
                        <p className="d-flex align-items-lg-center flex-lg-row flex-column">
                            <span className="fw-6">Interview Duration: </span>
                            <span className="info-text fw-6">{formatTime(form?.totalDuration)} minutes</span>
                        </p>
                        <p className="d-flex align-items-lg-center flex-lg-row flex-column">
                            <span className="fw-6"> Total Questions: </span>
                            <span className="info-text fw-6">{form?.totalQuestion}</span>
                        </p>
                    </div>
                    <p className="mt-2 mb-1 instruction_text">
                        Please follow these instructions carefully to ensure a smooth and successful interview process:
                    </p>
                    <ul className="mt-2 instruction_text instruction_list_container">
                        {instructions?.map((item, key) => (
                            <li key={key}>{item}</li>
                        ))}
                    </ul>
                    <p className="instruction_text mb-1">
                        If you encounter any technical issues during the interview, please contact our technical support
                        team at support@incruiter.com for assistance.
                    </p>
                    <p className="instruction_text mt-2 mb-1">
                        Thank you for participating. Your responses will help us assess your skills for the{' '}
                        {form?.positionTitle} position.
                    </p>
                    <p className="mt-2 mb-1 instruction_text mb-1">Good luck!</p>
                </div>
                <div className="pb-lg-0 pb-3 pt-lg-0 pt-1 mt-2">
                    <div className="form-check d-flex align-items-center justify-content-center">
                        <input
                            type="checkbox"
                            className="form-check-input me-2"
                            checked={form?.consent}
                            onChange={() => {
                                setForm((prevState) => ({
                                    ...prevState,
                                    consent: !form?.consent,
                                }));
                            }}
                            id="consent"
                        />
                        <label className="form-check-label instruction-label" htmlFor="consent">
                            <span>
                                I consent to the interview recording and the collection of my personal data as per{' '}
                            </span>
                            <a
                                className="privacy-policy"
                                href={generateUrl(PRIVACY_POLICY)}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span>Privacy Policy</span>
                                <img className="position-absolute" src={getAssetSrc(ASSET_NAMES.HYPERLINK)} alt="" />
                            </a>
                        </label>
                    </div>
                    <div className="form-check d-flex align-items-center justify-content-center">
                        <input
                            type="checkbox"
                            className="form-check-input me-2"
                            checked={form?.instructionAccepted}
                            onChange={() => {
                                setForm((prevState) => ({
                                    ...prevState,
                                    instructionAccepted: !form?.instructionAccepted,
                                }));
                            }}
                            id="accept"
                        />
                        <label className="form-check-label instruction-label" htmlFor="accept">
                            <span>I agree with the </span>
                            <a
                                className="privacy-policy"
                                href={generateUrl(TERMS_AND_CONDITION)}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span>Terms and Conditions</span>
                                <img className="position-absolute" src={getAssetSrc(ASSET_NAMES.HYPERLINK)} alt="" />
                            </a>
                        </label>
                    </div>
                    <Button
                        text="Start Interview"
                        className={`common-btn${
                            !form?.instructionAccepted || !form?.consent ? ' disabled' : ''
                        } mt-lg-2 mt-3 mx-auto w-100 mw-lg-100`}
                        onClick={handleJoin}
                    />
                </div>
            </div>
        </MainPage>
    );
};

export default Instructions;
