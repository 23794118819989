import React, { useEffect, useRef } from 'react';
import './Modal.css';
import { Button } from '@100mslive/roomkit-react';
import CrossIcon from '../../../assets/images/cross-white.svg';
import { createPortal } from 'react-dom';

export default function Modal({
    className = '',
    isOpen,
    onClose,
    showCloseButton,
    headerText,
    Content,
    imgSrc,
    buttonText,
    handleClick,
    isBtnDisabled,
    hideButton,
    type,
    primaryBtnText,
    handlePrimaryBtnClick,
}) {
    const modalRef = useRef();

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (showCloseButton && modalRef.current && !modalRef.current.contains(e.target)) onClose();
        };
        if (isOpen) document.addEventListener('click', handleOutsideClick);
        return () => document.removeEventListener('click', handleOutsideClick);
    }, [isOpen, onClose]);

    const modalJsx = () => {
        switch (type) {
            case 1:
                return (
                    <div className={`modal ${isOpen ? 'open' : ''}`}>
                        <div ref={modalRef} className={className + ' modal-type-1-container'}>
                            <div className="header-type-1-text">{headerText}</div>
                            <div className="modal-type-1-content-container">
                                {imgSrc && <img className="content-img" src={imgSrc} alt="" />}
                                <Content />
                            </div>
                            <div className="d-flex justify-content-between">
                                <Button className="modal-type-1-button primary-btn" onClick={handlePrimaryBtnClick}>
                                    {primaryBtnText}
                                </Button>
                                <Button className="modal-type-1-button" onClick={handleClick}>
                                    {buttonText}
                                </Button>
                            </div>
                        </div>
                    </div>
                );
            default:
                return (
                    <div className={`modal ${isOpen ? 'open' : ''}`}>
                        <div ref={modalRef} className={className + ' modal-container'}>
                            {showCloseButton && (
                                <img className="close-button" src={CrossIcon} alt="" onClick={onClose} />
                            )}
                            <div className="header-text">{headerText}</div>
                            <div className="title-line" />
                            <div className="modal-content-container">
                                {imgSrc && <img className="content-img" src={imgSrc} alt="" />}
                                <Content />
                            </div>
                            {!hideButton && (
                                <center>
                                    <Button
                                        type="submit"
                                        onClick={handleClick}
                                        className="modal-button"
                                        disabled={isBtnDisabled}
                                    >
                                        {buttonText}
                                    </Button>
                                </center>
                            )}
                        </div>
                    </div>
                );
        }
    };

    return createPortal(modalJsx(), document.getElementById('modal'));
}
