import React from 'react';
import { formatTime } from '../../Feedback/components/Helpers';
import SidebarQuestion from '../components/portal/SidebarQuestion';
import SubmitIcon from '../../../assets/images/submit-icon.svg';
import Button from '../../commonComponent/Button';
import { getAssetSrc } from '../../../utils/helper';
import { ASSET_NAMES } from '../../../common/constants';
const PracticeQuestion = ({
    questionInfo,
    isSpeaking,
    totalQuestion,
    isSkipEnabled,
    prepareTime,
    answerTime,
    skipTimer,
    handleEndCall,
    isButtonDisabled,
    handleNextQuestionClick,
    isNextButtonEnabled,
    question,
}) => {
    const skipButtonEnabled = !isSpeaking && isSkipEnabled;
    const submitAnswerButtonEnabled = !isSpeaking && !isButtonDisabled && isNextButtonEnabled;
    const renderText = (label, imgSrc) => (
        <>
            <span>{label}</span>
            {imgSrc && <img className="btn-icon" src={imgSrc} alt="" />}
        </>
    );

    return (
        <div className="sidebar-container">
            <div className="question-timer-container d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    {totalQuestion >= questionInfo?.questionNumber && (
                        <Button
                            text={renderText('Skip Prep Time', getAssetSrc(ASSET_NAMES.SKIP_FORWARD))}
                            className={`common-btn timer-btn${skipButtonEnabled ? '' : ' disabled'}`}
                            onClick={skipTimer}
                            disabled={!skipButtonEnabled}
                        />
                    )}
                    <div className={`time-countdown ${prepareTime < 1 ? 'visibility-hidden' : ''}`}>
                        <span>{prepareTime ?? '0'}</span>
                        <span className="time-unit">{prepareTime > 1 ? 'secs' : 'sec'}</span>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    {totalQuestion > questionInfo?.questionNumber ? (
                        <Button
                            text={renderText('Next Question', getAssetSrc(ASSET_NAMES.NEXT_QUESTION))}
                            className={`common-btn timer-btn${!submitAnswerButtonEnabled ? ' disabled' : ''}`}
                            onClick={handleNextQuestionClick}
                            disabled={!submitAnswerButtonEnabled}
                        />
                    ) : totalQuestion === questionInfo?.questionNumber ? (
                        <Button
                            text={renderText('Submit Answer', SubmitIcon)}
                            className={`common-btn timer-btn${!submitAnswerButtonEnabled ? ' disabled' : ''}`}
                            onClick={handleNextQuestionClick}
                            disabled={!submitAnswerButtonEnabled}
                        />
                    ) : (
                        <Button
                            text={renderText('Submit', SubmitIcon)}
                            className="common-btn timer-btn"
                            onClick={() => handleEndCall()}
                        />
                    )}
                    <div className="next-ques-time-countdown">{formatTime(answerTime) ?? '00:00'}</div>
                </div>
            </div>
            <SidebarQuestion question={question} />
        </div>
    );
};

export default PracticeQuestion;
