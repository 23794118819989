import React, { memo } from 'react';
import Finish_Icon from '../../../../assets/images/portal/finish-icon.svg';
import Button from '../../../commonComponent/Button';

const FinishInterviewPopUp = memo(({ setIsFinish, handleLeave }) => {
    return (
        <div className="finish-interview-popup">
            <div className="finish-interview-popup-content rounded d-flex flex-column p-4">
                <div className="d-flex gap-20 align-items-center mb-4">
                    <img src={Finish_Icon} alt="" className="finish-logo img-fluid" />
                    <div className="finish-title-end">Finish This Interview</div>
                </div>
                <div className="finish-subtitle">
                    Do you want to finish the interview? Once you click on finish, You can not re-join the interview.
                </div>
                <div className="d-center gap-10 w-100 mb-3 mt-4">
                    <Button text="Cancel" className="common-btn cancel" onClick={() => setIsFinish(false)} />
                    <Button text="Finish" className="common-btn" onClick={() => handleLeave(false, 'End Call')} />
                </div>
            </div>
        </div>
    );
});

export default FinishInterviewPopUp;
