import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex } from '@100mslive/roomkit-react';
import debounce from 'lodash/debounce';
import { Completed } from './components/Completed';
import Instructions from './components/portal/Instructions';
import Verify from './components/portal/Verify';
import { verifyCandidateKey, verifyRoom } from '../../redux/action';
import FullPageProgress from '../FullPageProgress';
import { ToastManager } from '../Toast/ToastManager';
import CanceledInterview from './CanceledInterview';
import { LinkExpired } from './components/LinkExpired';
import { isMobileOrTabletDevice } from '../../utils/helper';
import MobileDeviceUnsupported from './components/mobile/MobileDeviceUnsupported';

const InterviewRoom = ({ verifyOtp = false }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { roomId } = useParams();
    const loading = useSelector((state) => state.Interview.loading);
    const roomVerifyStatus = useSelector((state) => state.Interview?.roomVerifyStatus);
    const verifyKey = useSelector((state) => state.Interview?.verifyKey);
    useEffect(() => {
        if (!verifyKey && roomId) {
            dispatch(verifyRoom({ roomId: roomId }));
        }
    }, [dispatch, roomId, verifyKey]);

    const [isDisabled, setIsDisabled] = useState(true);
    const [instructions, setInstructions] = useState([]);
    const [form, setForm] = useState({
        otp: '',
        otpVerified: false,
        instructionAccepted: false,
        interviewId: '',
        questionId: '',
        candidateName: '',
        jobDescription: '',
        positionTitle: '',
        companyName: '',
        candidateEmail: '',
        candidatePhone: '',
        totalDuration: '',
        totalQuestion: '',
        consent: false,
    });
    useEffect(() => {
        setInstructions([
            `There are ${form?.totalQuestion} questions in the interview.`,
            'Please use Chrome browser and laptop for the best interview experience.',
            'Kindly ensure that you disconnect the VPN before initiating the interview.',
            "Once the question is skipped, you can't go back. The response can only be recorded once.",
            'After reading each question, you will be given some time to prepare your response before the recording begins.',
            'Each question will have a set amount of time to record your response.',
            'Your response will be automatically submitted when the time limit is reached or you can submit your response and proceed to the next question.',
            'It’s mandatory to switch on audio and video for the interview process.',
            'Any attempt to manipulate or misuse the interview platform may result in disqualification.',
            'Please do not refresh the page during the interview.',
            'The interview shall be recorded throughout the interview for quality and monitoring purposes.',
        ]);
    }, [form?.totalQuestion]);

    useEffect(() => {
        if (form?.otp && form?.otp.length === 4) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [form.otp]);
    useEffect(() => {
        setForm((prevState) => ({
            ...prevState,
            interviewId: roomVerifyStatus?._id,
            candidateName: roomVerifyStatus?.candidate?.name,
            jobDescription: roomVerifyStatus?.position?.jobDescription,
            positionTitle: roomVerifyStatus?.position?.title,
            companyName: roomVerifyStatus?.companyName,
            candidateEmail: roomVerifyStatus?.candidate?.email,
            candidatePhone: roomVerifyStatus?.candidate?.phone,
            totalDuration: roomVerifyStatus?.question?.totalDuration,
            totalQuestion: roomVerifyStatus?.question?.totalQuestion,
            questionId: roomVerifyStatus?.question?._id,
        }));
    }, [roomVerifyStatus]);

    useEffect(() => {
        setForm((prevState) => ({
            ...prevState,
            otpVerified: verifyKey?.status ?? false,
        }));
    }, [verifyKey]);

    const handleSubmit = debounce(() => {
        if (form?.otp && form?.otp.length === 4) {
            dispatch(
                verifyCandidateKey({
                    form: { interviewId: form?.interviewId, key: form?.otp },
                })
            );
        } else {
            ToastManager.addToast({
                title: 'Please enter the valid OTP.',
            });
        }
    }, 300);

    const handleJoin = debounce(() => {
        if (form?.instructionAccepted && form?.consent) {
            navigate(`/preview/candidate/${roomId}`, { state: form });
        } else if (!form?.consent) {
            ToastManager.addToast({
                title: 'Please give the consent to record the interview.',
            });
        } else {
            ToastManager.addToast({
                title: 'Please accept the instructions to continue.',
            });
        }
    }, 300);

    useEffect(() => {
        const handleStopCameraStream = async () => {
            try {
                const devices = await navigator?.mediaDevices?.enumerateDevices();
                if (!devices) return;
                const cameras = devices?.filter((device) => device.kind === 'videoinput');
                cameras?.forEach(async (device) => {
                    try {
                        const stream = await navigator?.mediaDevices?.getUserMedia({
                            video: { deviceId: device.deviceId },
                        });
                        if (!stream) return;
                        const tracks = stream?.getTracks();
                        tracks.forEach((track) => track?.stop());
                    } catch (error) {
                        console.error('Error stopping camera stream:', error);
                    }
                });
            } catch (error) {
                console.error('Error fetching media devices:', error);
            }
        };
        if (
            roomVerifyStatus &&
            (roomVerifyStatus.interviewStatus === 'Canceled' ||
                roomVerifyStatus.roomExpired ||
                !roomVerifyStatus.status)
        )
            handleStopCameraStream();
    }, [roomVerifyStatus]);

    if (isMobileOrTabletDevice() && roomVerifyStatus?.isCodingQuestionAvailable) return <MobileDeviceUnsupported />;

    return loading ? (
        <FullPageProgress />
    ) : roomVerifyStatus ? (
        <Flex css={{ size: '100%', background: '#2e2e41' }} direction="column">
            {roomVerifyStatus?.interviewStatus === 'Canceled' ? (
                <CanceledInterview />
            ) : roomVerifyStatus?.roomExpired ? (
                <LinkExpired />
            ) : roomVerifyStatus?.status ? (
                form?.otpVerified ? (
                    <Instructions
                        form={form}
                        setForm={setForm}
                        instructions={instructions}
                        handleJoin={handleJoin}
                        className="instructions"
                    />
                ) : (
                    <Verify
                        form={form}
                        setForm={setForm}
                        handleSubmit={handleSubmit}
                        verifyOtp={verifyOtp}
                        isDisabled={isDisabled}
                    />
                )
            ) : (
                <Completed />
            )}
        </Flex>
    ) : (
        <FullPageProgress />
    );
};

export default InterviewRoom;
