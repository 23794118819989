import React from 'react';
import { Footer as AppFooter } from '@100mslive/roomkit-react';
import { MobileLeaveRoom } from '../components/mobile/MobileLeaveRoom';
const PracticeMobileProgressBar = ({ totalQuestions, activeQuestion, handleEndCall }) => {
    const circles = [
        <div key={1} className={`mobile-progress-circle ${activeQuestion <= 1 ? 'active' : 'filled'}`}>
            1
        </div>,
        <React.Fragment key={2}>
            <hr className="mobile-progress-line" />
            <div
                className={`mobile-progress-circle ${activeQuestion <= 1 ? 'not-filled' : activeQuestion === 2 ? 'active' : 'filled'}`}
            >
                2
            </div>
        </React.Fragment>,
        <React.Fragment key={3}>
            <hr className="mobile-progress-line" />
            <div className={`mobile-progress-circle ${activeQuestion >= totalQuestions ? 'active' : 'not-filled'}`}>
                3
            </div>
        </React.Fragment>,
    ];

    return (
        <div className="progress-question-bar" style={{ gap: 10 }}>
            {circles}
            <AppFooter.Root>
                <AppFooter.Center>
                    <MobileLeaveRoom handleEndCall={handleEndCall} />
                </AppFooter.Center>
            </AppFooter.Root>
        </div>
    );
};

export default PracticeMobileProgressBar;
