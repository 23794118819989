import React from 'react';

const PracticeProgressBar = ({ totalQuestions, activeQuestion }) => {
    const circles = [
        <div key={1} className={`progress-circle ${activeQuestion <= 1 ? 'active' : 'filled'}`}>
            1
        </div>,
        <React.Fragment key={2}>
            <hr className="progress-line" />
            <div
                className={`progress-circle ${activeQuestion <= 1 ? 'not-filled' : activeQuestion === 2 ? 'active' : 'filled'}`}
            >
                2
            </div>
        </React.Fragment>,
        <React.Fragment key={3}>
            <hr className="progress-line" />
            <div className={`progress-circle ${activeQuestion >= totalQuestions ? 'active' : 'not-filled'}`}>3</div>
        </React.Fragment>,
    ];

    return <div className="progress-question-bar">{circles}</div>;
};

export default PracticeProgressBar;
