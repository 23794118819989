import React from 'react';
import Button from '../../../commonComponent/Button';

const MobileNextPopUpBeam = ({ popupInputs, interviewFinished }) => {
    const { idealPopup, isDisabled, questionNumber, isNextClick } = popupInputs;
    let buttonContent, mainContent;
    if (questionNumber === 2) {
        buttonContent = 'Start';
        mainContent = "Hurrah! We are all set to begin your interview. Please click on 'Start' button.";
    } else if (isNextClick) {
        buttonContent = 'Okay';
        mainContent = "Let's proceed to the next question";
    } else if (interviewFinished) {
        buttonContent = 'Okay';
        mainContent = 'Your time to answer is over. The interview has been ended.';
    } else {
        buttonContent = 'Next Question';
        mainContent = "Your time to answer is over. Click on 'Next Question' to move ahead.";
    }
    return (
        <div className={`mobile-next-popup ${isDisabled ? 'd-none' : 'd-flex'}`}>
            <div className="mobile-next-popup-inner">
                <div className="mobile-next-popup-content">
                    {!idealPopup ? mainContent : "We didn't find any action to move ahead with the interview process."}
                    {idealPopup && <br />}
                    {idealPopup && 'Do you wish to end the interview?'}
                </div>
                {!idealPopup && <Button text={buttonContent} className={`mobile-common-btn mobile-popup-btn mt-3`} />}
                {idealPopup && (
                    <div className="mobile-next-popup-buttons mt-3">
                        <Button text="Cancel" className={`mobile-common-btn mobile-popup-btn cancel`} />
                        <Button text="Finish" className={`mobile-common-btn mobile-popup-btn ml-2`} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default MobileNextPopUpBeam;
