import React, { useEffect, useState } from 'react';
import useSound from 'use-sound';
import { selectPeers, useHMSStore } from '@100mslive/react-sdk';
import { Box } from '@100mslive/roomkit-react';
import MQuestion from '../components/mobile/MQuestion';
import beep from '../../../assets/audio/beep.mp3';
import { GridCenterView } from '../../gridView';
import PracticeMobileHeader from './PracticeMobileHeader';
import PracticeMobileNextPopUp from './PracticeMobileNextPopUp';
import PracticeQuestionButton from './PracticeQuestionButton';
const PracticeMobile = React.memo(
    ({
        form,
        roomId,
        questionInfo,
        isPlaying,
        prepareTime,
        setPrepareTime,
        answerTime,
        setAnswerTime,
        handleEndCall,
        interviewStarted,
        onlineStatus,
        setAnswerDuration,
        answerDuration,
        handleSubmit,
        displayInput,
        popupInputs,
        setPopupInputs,
        playAudio,
        skipTimer,
        waitingPrepTime,
        setDisplayInput,
        token,
    }) => {
        const peers = useHMSStore(selectPeers);
        const candidatePeer = peers.filter((peer) => peer.roleName === 'candidate');
        const viewportHeight = window.innerHeight;
        const calculateHeight = (value) => {
            const difference = viewportHeight - (viewportHeight * (100 - value)) / 100;
            return `${difference}px`;
        };

        const [playBeep] = useSound(beep);
        const prepareTimeOver = "Your time to prepare is over. It's time to begin the answer.";
        const answerTimeOver = 'Your time to answer is over. We are proceeding to the next question.';
        const [questionEnded, setQuestionEnded] = useState(false);
        const [startPrepTime, setStartPrepTime] = useState(false);
        const [startAnswerTime, setStartAnswerTime] = useState(false);

        const handleSpeak = async () => {
            const value = {
                ...popupInputs,
                isDisabled: true,
                isNextClick: false,
            };
            setPopupInputs(value);
            playAudio();
        };

        useEffect(() => {
            if (!isPlaying && questionEnded && answerTime === 0) {
                const timerId = setTimeout(() => {
                    handleSubmit('Mobile');
                    setQuestionEnded(false);
                }, 200);
                return () => clearTimeout(timerId);
            }
        }, [questionEnded, isPlaying, answerTime]);

        useEffect(() => {
            if (!waitingPrepTime && !isPlaying && interviewStarted && !questionEnded && answerTime > 0) {
                setStartPrepTime(true);
            }
        }, [isPlaying, waitingPrepTime]);

        useEffect(() => {
            if (!isPlaying && prepareTime === 0 && interviewStarted) {
                setStartAnswerTime(true);
            }
        }, [prepareTime, isPlaying]);

        useEffect(() => {
            if (displayInput?.playBeepSound) {
                const value = {
                    ...displayInput,
                    playBeepSound: false,
                };
                setDisplayInput(value);
            }
        }, [displayInput?.playBeepSound]);

        useEffect(() => {
            let prepareTimer;
            const prepTimerFunc = async () => {
                if (prepareTime > 0) {
                    setPrepareTime(prepareTime - 1);
                } else {
                    setStartPrepTime(false);
                    if (!popupInputs?.isNextClick) {
                        const dValue = {
                            ...displayInput,
                            playBeepSound: true,
                        };
                        setDisplayInput(dValue);
                        playBeep();
                    }
                    clearInterval(prepareTimer);
                }
            };
            if (startPrepTime) {
                prepareTimer = setInterval(() => {
                    !onlineStatus ? clearInterval(prepareTimer) : prepTimerFunc();
                }, 1000);
                return () => clearInterval(prepareTimer);
            }
        }, [startPrepTime, prepareTime, onlineStatus, popupInputs?.isNextClick]);

        useEffect(() => {
            if (startAnswerTime) {
                let answerTimer;
                const ansTimerFunc = () => {
                    if (answerTime > 0) {
                        setAnswerTime(answerTime - 1);
                        setAnswerDuration(answerDuration + 1);
                    } else {
                        setStartAnswerTime(false);
                        setQuestionEnded(true);
                        clearInterval(answerTimer);
                    }
                };
                answerTimer = setInterval(() => {
                    !onlineStatus ? clearInterval(answerTimer) : ansTimerFunc();
                }, 1000);
                return () => clearInterval(answerTimer);
            }
        }, [startAnswerTime, answerTime, onlineStatus]);

        useEffect(() => {
            if (
                displayInput?.message &&
                displayInput?.message !== prepareTimeOver &&
                displayInput?.message !== answerTimeOver
            ) {
                const mValue = {
                    ...popupInputs,
                    isDisabled: false,
                };
                setPopupInputs(mValue);
            }
        }, [displayInput?.message]);

        return (
            <>
                <PracticeMobileNextPopUp
                    onButtonClick={handleSpeak}
                    questionNumber={parseInt(popupInputs?.questionNumber)}
                    handleEndCall={handleEndCall}
                    interviewFinished={parseInt(popupInputs?.questionNumber) > parseInt(form?.totalQuestion) + 1}
                    popupInputs={popupInputs}
                    setPopupInputs={setPopupInputs}
                />
                <Box
                    css={{
                        w: '100%',
                        flex: '1 1 0',
                        minHeight: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    id="conferencing"
                    data-testid="conferencing"
                >
                    <div
                        style={{
                            height: calculateHeight(20),
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <PracticeMobileHeader
                            activeQuestion={parseInt(questionInfo?.questionNumber)}
                            totalQuestion={parseInt(form?.totalQuestion)}
                            prepareTime={prepareTime}
                            answerTime={answerTime}
                            handleEndCall={handleEndCall}
                        />
                    </div>
                    <div style={{ height: calculateHeight(35) }}>
                        <GridCenterView
                            peers={candidatePeer}
                            maxTileCount={1}
                            isMobile
                            form={form}
                            practiceStatus={true}
                        />
                    </div>
                    <div style={{ height: calculateHeight(35) }}>
                        <MQuestion
                            question={
                                isPlaying || (!isPlaying && !popupInputs?.isDisabled)
                                    ? displayInput?.message
                                    : displayInput?.displayText
                            }
                            isDisabled={popupInputs?.isDisabled}
                        />
                    </div>
                    <div style={{ height: calculateHeight(10) }}>
                        <PracticeQuestionButton
                            handleEndCall={handleEndCall}
                            questionType={parseInt(questionInfo?.questionNumber) < parseInt(form?.totalQuestion)}
                            submitAnswerButtonEnabled={startAnswerTime || startPrepTime}
                            skipTimer={skipTimer}
                            skipButtonEnabled={startPrepTime}
                            setPrepareTime={setPrepareTime}
                            setAnswerTime={setAnswerTime}
                            popupInputs={popupInputs}
                            setPopupInputs={setPopupInputs}
                        />
                    </div>
                </Box>
            </>
        );
    }
);

export default PracticeMobile;
