import React from 'react';
import { OverAllSkill } from './components/OverAllSkill';
import { ResumeSummary } from './components/ResumeSummary';
export const OverallSkills = ({ data }) => {
    return (
        <div className="overall-skills-div break-stop">
            <ResumeSummary data={data} />
            <OverAllSkill data={data} />
        </div>
    );
};
