import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'regenerator-runtime/runtime';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { ResultReason } from 'microsoft-cognitiveservices-speech-sdk';
import { selectLocalPeer, useAVToggle, useHMSStore, usePreviewJoin } from '@100mslive/react-sdk';
import { Flex, Loading } from '@100mslive/roomkit-react';
import SidePane from '../../../layouts/SidePane';
import { defaultPreviewPreference, UserPreferencesKeys, useUserPreferences } from '../../hooks/useUserPreferences';
import { CameraMicPermission } from '../../Pages/components/CameraMicPermission';
import { NetworkConnectionLost } from '../../Pages/components/NetworkConnectionLost';
import AudioVideoPrechecks from './components/AudioVideoPrechecks';
import AcknowledgeModal from './components/AcknowledgeModal';

const speechSdk = require('microsoft-cognitiveservices-speech-sdk');

const PreviewJoin = ({
    token,
    onJoin,
    env,
    form,
    skipPreview,
    initialName,
    asRole,
    setIsMount,
    positionTitle,
    companyName,
    isNetworkError,
    permissionsChecks,
    downlinkQuality,
    mediaRecorder,
    startRecording,
    setCheckNetworkError,
    isButtonDisabled,
}) => {
    const localPeer = useHMSStore(selectLocalPeer);
    const peerId = localPeer?.id;
    const [, setPreviewPreference] = useUserPreferences(UserPreferencesKeys.PREVIEW, defaultPreviewPreference);
    const [value, setValue] = useState('');
    const [speakingPermission, setSpeakingPermission] = useState(true);
    const [name, setName] = useState('');
    const { isLocalAudioEnabled, isLocalVideoEnabled } = useAVToggle();
    const [previewError, setPreviewError] = useState(false);
    const [testValidation, setTestValidation] = useState(false);
    const [permissionChecksFailed, setPermissionChecksFailed] = useState(false);
    const [azureError, setAzureError] = useState(false);
    const [azureValue, setAzureValue] = useState([]);
    const videoContainerRef = useRef(null);
    const { transcript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    function sttFromMic() {
        const speechConfig = speechSdk.SpeechConfig.fromSubscription(
            process.env.REACT_APP_MICROSOFT_KEY,
            process.env.REACT_APP_MICROSOFT_REGION
        );
        speechConfig.speechRecognitionLanguage = 'en-US';

        const audioConfig = speechSdk.AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new speechSdk.SpeechRecognizer(speechConfig, audioConfig);

        const results = [];
        recognizer.startContinuousRecognitionAsync();
        recognizer.recognized = (s, e) => {
            if (e.result.reason == ResultReason.RecognizedSpeech) {
                results.push(e.result.text);
                setAzureValue([...results]);
            } else if (e.result.reason == ResultReason.NoMatch) {
                setAzureError('Speech could not be recognized.');
            }
        };
    }

    useEffect(() => {
        if (!permissionsChecks) {
            const checkAndUpdateState = () => {
                setPermissionChecksFailed(true);
            };
            const timeoutId = setTimeout(() => {
                checkAndUpdateState();
            }, 30000);
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [permissionsChecks]);

    useEffect(() => {
        if (!name) setName(initialName);
    }, [initialName]);

    const startListening = () => {
        SpeechRecognition.startListening({ continuous: true });
        sttFromMic();
    };

    const stopListening = () => {
        SpeechRecognition.stopListening();
    };

    useEffect(() => {
        // Combine the transcriptions from the results into a single string
        const newAzureTranscription = azureValue.join(' ');
        setValue(transcript || newAzureTranscription);
    }, [transcript, azureValue]);
    useEffect(() => {
        if (!browserSupportsSpeechRecognition && azureError) {
            console.error('Speech recognition is not supported in this browser or on this device.');
            // Handle the lack of support, disable speech-related features, or show a message to the user.
            setSpeakingPermission(false);
        }
    }, [browserSupportsSpeechRecognition, azureError]);

    const { preview, join } = usePreviewJoin({
        name,
        token,
        initEndpoint: env ? `https://${env}-init.100ms.live/init` : undefined,
        initialSettings: {
            isAudioMuted: skipPreview || false,
            isVideoMuted: skipPreview || false,
            speakerAutoSelectionBlacklist: ['Yeti Stereo Microphone'],
        },
        captureNetworkQualityInPreview: true,
        handleError: (_, method) => {
            if (method === 'preview') {
                setPreviewError(true);
            }
        },
        asRole,
    });
    useEffect(() => {
        if (testValidation) {
            setIsMount(true);
        }
    }, [testValidation]);
    const savePreferenceAndJoin = useCallback(() => {
        stopListening();
        setPreviewPreference({
            name,
            isAudioMuted: !isLocalAudioEnabled,
            isVideoMuted: !isLocalVideoEnabled,
        });
        join();
        onJoin && onJoin();
    }, [join, isLocalAudioEnabled, isLocalVideoEnabled, name, setPreviewPreference, onJoin]);
    useEffect(() => {
        if (token) {
            if (skipPreview) {
                savePreferenceAndJoin();
            } else {
                preview();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, skipPreview]);
    return isNetworkError ? (
        <NetworkConnectionLost />
    ) : testValidation ? (
        <AcknowledgeModal
            onJoin={savePreferenceAndJoin}
            name={name}
            form={form}
            token={token}
            previewError={previewError}
            value={value}
            speakingPermission={speakingPermission}
            positionTitle={positionTitle}
            companyName={companyName}
            videoContainerRef={videoContainerRef}
            recorder={mediaRecorder}
            isButtonDisabled={isButtonDisabled}
        />
    ) : (
        <Flex
            css={{
                flex: '1 1 0',
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                overflowY: 'auto',
                width: '100%',
                '@md': {
                    height: '100%',
                },
            }}
            justify="center"
            align="center"
        >
            {token ? (
                permissionChecksFailed ? (
                    <CameraMicPermission />
                ) : (
                    <AudioVideoPrechecks
                        peerId={peerId}
                        isLocalAudioEnabled={isLocalAudioEnabled}
                        isLocalVideoEnabled={isLocalVideoEnabled}
                        downlinkQuality={downlinkQuality}
                        startRecording={startRecording}
                        startListening={startListening}
                        setTestValidation={setTestValidation}
                        setCheckNetworkError={setCheckNetworkError}
                    />
                )
            ) : (
                <Loading size={100} />
            )}
            <SidePane
                css={{
                    position: 'unset',
                    mr: '$10',
                    '@lg': { position: 'fixed', mr: '$0' },
                }}
            />
        </Flex>
    );
};

export default PreviewJoin;
