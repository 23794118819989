import React from 'react';
import cameraMicPermission from '../../../assets/images/cameraMicPermission.svg';
import Modal from '../../commonComponent/Modal/Modal';
export const CameraMicPermission = () => {
    const headerText = 'Camera and Microphone Permission Denied/Unavailable';
    const handleClick = () => {
        window.location.reload(false);
    };
    const Content = () => (
        <div className="popper_message_div d-center">
            <div className="finish-title-m">
                Uh-oh! it seems like we are having trouble accessing your camera and microphone.
            </div>
            <div className="finish-title-m pb-1 pt-1">
                🎥 To proceed further, please grant permission for camera and microphone access. This will ensure a
                seamless interview experience.
            </div>
            <div className="finish-title-m pb-1 pt-1">
                If you are experiencing issues with permission:
                <div>
                    <div>
                        <span style={{ color: '#12B6BC' }}>1.</span> Try refreshing the page to allow permission again.
                    </div>
                    <div>
                        <span style={{ color: '#12B6BC' }}>2.</span> Alternatively, you can try using a private or
                        incognito window in your browser.
                    </div>
                    <div>
                        <span style={{ color: '#12B6BC' }}>3.</span> Use Chrome in Laptop for best interview experience
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <Modal
            isOpen
            headerText={headerText}
            imgSrc={cameraMicPermission}
            buttonText="Refresh"
            handleClick={handleClick}
            Content={Content}
        />
    );
};
